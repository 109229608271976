export const filtros = {
  "especificos.firmaElectronica": {
    "tieneFirmaElectronica": ["si", "no", "enDesarrollo"],
    "tipoFirma": ["simple", "avanzado"],
    "caracteristicasFirmaElectronica.tipoDesarrollo": [
      "interno",
      "externo",
      "sat",
      "firel",
    ],
    "numeroCertificadosAño": ["ver"],
  },
  "especificos.sistemaGestion": {
    "tieneSistemaGestion": [
      "si",
      "no",
    ],
    "numeroSistemas": ["ver"],
    "interoperabilidadInstitucionesExternas": ["si", "no"],
  },
  "especificos.expedienteElectronico": {
    "tieneExpediente": [
      "si",
      "no",
    ],
    "tipoExpediente": [
      "avanzado",
      "digitalizado"
    ],
    "mecanismoAccesoExpediente": [
      "usuarioContraseña",
      "firmaElectronica",
    ],
    "tipoDesarrollo": [
      "interno",
      "privado",
      "colaboracion"
    ],
    "numeroUsuariosRegistradosExternos": ["ver"],
    "numeroConsultasAlExpediente": ["ver"],
  },
  "especificos.plataformaInterponerDemanda": {
    "tienePlataformaEnviarRecibirDemandasPromociones":
      ["si", "no"],
    "tipoPlataforma": [
      "interponerDemandas",
      "interponerPromociones",
    ],
    "mecanismoAcceso": [
      "usuarioContraseña",
      "firmaElectronicaAvanzada",
    ],
    "utilizaFirmaElectronicaAvanzada":
      ["si", "no"],
    "numeroPromocionesPresentadasAño": ["ver"],
    "numeroDemandasPresentadasAño": ["ver"],
  },
  "especificos.herramientaVideoconferencias": {
    "realizaAudienciasDistanciaMedioVideoconferencias": [
      "si",
      "no",
    ],
    "tieneProtocolosRealizarAudienciasVideoconferencia": [
      "si",
      "no",
    ],
    "maneraVerificaIdentidadPersonasParticipanAudienciasRemotas":
      [
        "identificacionFrenteCamara",
        "verificacionPreviaAudienciaPorFuncionarioSalaRegistro",
        "firmaElectronica",
      ],
    "numeroAudienciasManeraRemota": ["ver"],
  },
  "especificos.juiciosLinea": {
    "tieneJuiciosLinea": ["si", "no"]
  },
  //"generales.tipoDesarrollo": ["interno", "externo", "hibrido"],
  "generales.tipoUsuarioHerramienta": {
    "interno": [
      "secretariosProyectistas",
      "jueces",
      "magistrados",
      "otrosFuncionarios",
    ],
    "externo": ["externo"],
    "numeroUsuariosInternosExternos": ["ver"],
    "numeroNotificacionesRealizadasAño": ["ver"],
    "numeroConsultasRealizadasAño": ["ver"],
  },
  "generales.materiasHaImplementado": [
    "civil",
    "familiar",
    "mercantil",
    "penal",
    "laboral"
  ],
};
