/* eslint-disable no-magic-numbers */
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { heads } from "../utils/headsGoodPractices";

const excelCloudWords = (buenasPracticas) => {
  const workbook = new Workbook();
  workbook.creator = "MéxicoEvalua";
  workbook.lastModifiedBy = "test";
  workbook.created = new Date();
  workbook.modified = new Date();

  let sheet = workbook.addWorksheet("MéxicoEvalua_Julio2021");

  sheet.getRow(1).values = ["México Evalua Datos a Julio de 2021"];
  sheet.getRow(3).values = ["Buenas Practicas"];

  sheet.getRow(3).height = 40;

  sheet.addRow(heads);

  buenasPracticas.map((goodPractice, index) => {
    sheet.addRow([(index + 1), ...Object.values(goodPractice)]);
  });

  sheet.getRow(4).height = 45;
  sheet.mergeCells("A1:C2");
  sheet.mergeCells("A3:C3");

  const row = sheet.getRow(4);
  row.eachCell((cell, rowNumber) => {
    sheet.getColumn(rowNumber).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };

    sheet.getColumn(rowNumber).width = 20;

    if (rowNumber % 2 === 0) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "94D4C4" },
      };
      cell.font = {
        size: 12,
        family: 2,
        color: { theme: 1 },
      };
    } else {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00868D" },
      };
      cell.font = {
        size: 12,
        family: 2,
        color: { theme: 0 },
      };
    }
  });

  sheet.getColumn(3).width = 50;

  workbook.xlsx
    .writeBuffer()
    .then(function (buffer) {
      const blob = new Blob([buffer], { type: "aplication/xlsx" });
      saveAs(blob, "MéxicoEvalua_BuenasPracticas_Busqueda.xlsx");
    })
    .catch((error) => console.log(error));
};

export default excelCloudWords;
