export const expElect = [
    [
      { name: "Cuenta con expediente electrónico", tam: 1 },
      { name: "Tipo de expediente", tam: 2 },
      { name: "Mecanismo de acceso al expediente", tam: 2 },
      { name: "Año de implementación", tam: 1 },
      { name: "Tipo de desarrollo", tam: 3 },
      { name: "Tipo de usuario de la herramienta", tam: 2 },
      {
        name: "Número de usuarios externos registrados para consultar el expediente electrónico",
        tam: 5,
      },
      {
        name: "Número de consultas realizadas al expediente electrónico por las y los usuarios externos",
        tam: 5,
      },
      {
        name: "Número de notificaciones realizadas de manera electrónica",
        tam: 5,
      },
      {
        name: "Materias en las que se ha implementado el expediente electrónico",
        tam: 5,
      },
    ],
    [
      { name: "", tam: 1 },
      { name: "Avanzado", tam: 1 },
      { name: "Digitalizado", tam: 1 },
      { name: "Usuario y contraseña", tam: 1 },
      { name: "Firma electrónica", tam: 1 },
      { name: "", tam: 1 },
      { name: "Interno", tam: 1 },
      { name: "Empresa privada", tam: 1 },
      { name: "En colaboración con otras instituciones", tam: 1 },
      { name: "Interno", tam: 1 },
      { name: "Externo", tam: 1 },
      { name: "2018", tam: 1 },
      { name: "2019", tam: 1 },
      { name: "2020", tam: 1 },
      { name: "2021 (enero a junio)", tam: 1 },
      { name: "Total 2018-2021", tam: 1 },
      { name: "2018", tam: 1 },
      { name: "2019", tam: 1 },
      { name: "2020", tam: 1 },
      { name: "2021 (al 30 de junio)", tam: 1 },
      { name: "Total consultas 2018-2021", tam: 1 },
      { name: "2018", tam: 1 },
      { name: "2019", tam: 1 },
      { name: "2020", tam: 1 },
      { name: "2021 (al 30 de junio)", tam: 1 },
      { name: "Total de notificaciones 2018-2021", tam: 1 },
      { name: "Laboral", tam: 1 },
      { name: "Penal", tam: 1 },
      { name: "Mercantil", tam: 1 },
      { name: "Familiar", tam: 1 },
      { name: "Civil", tam: 1 },
    ],
  ];