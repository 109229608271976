import React from "react";
import SubTemaTresUno from "../TemaTres/subTemaTresUno";
import SubTemaTresDos from "../TemaTres/subTemaTresDos";
import SubTemaTresTres from "../TemaTres/subTemaTresTres";
import SubTemaTresCuatro from "../TemaTres/subTemaTresCuatro";
import SubTemaTresCinco from "../TemaTres/subTemaTresCinco";
import SubTemaTresSeis from "../TemaTres/subTemaTresSeis";

export default function FaseProcesoTres(props) {
  const { setBack } = props;
  return (
    <section>
      <SubTemaTresUno setBack={setBack} />
      <SubTemaTresDos setBack={setBack} />
      <SubTemaTresTres setBack={setBack} />
      <SubTemaTresCuatro setBack={setBack} />
      <SubTemaTresCinco setBack={setBack} />
      <SubTemaTresSeis setBack={setBack} />
    </section>
  );
}
