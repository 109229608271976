import React from "react";
import Slide from "../Slide";

import icon21 from "../icons/icon2.1.png";
import icon211 from "../icons/icon2.1.1.png";
import icon212 from "../icons/icon2.1.2.png";
import icon213 from "../icons/icon2.1.3.png";

export default function SubtemaUno(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        prev="#0/0"
        titulo="Planificación del proceso"
        subtitulo="Liderazgo para la transformación"
        numSubtitulo="2.1"
        icon={icon21}
        cuerpo="Esta etapa consiste en consolidar un grupo que lidere la transformación tecnológica. Este grupo puede integrarse por perfiles que representen diversas perspectivas, que lidere la transformación y que establezca principios que guíen el proceso de transformación tecnológica.

        La integración de perfiles no debe limitarse a incluir sólo jueces, abogados y administradores de juzgados. De acuerdo con diversos expertos, esto casi nunca da buenos resultados, ya que son este tipo de actores los que originalmente diseñaron el sistema de justicia y básicamente se estaría replicando la misma lógica.
        
        Los elementos que hay que considerar para la consolidación de este grupo son los siguientes. "
        subtemas={[
          { texto: "Identificación de actores clave ", num: "2.1.1" },
          { texto: "Reuniones de trabajo", num: "2.1.2" },
          { texto: "Visión", num: "2.1.3" },
        ]}
        casosDeEstudio={[
          "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
          "Poder Judicial de Yucatán: intervención con principios de diseño centrado en el usuario",
          "Poder Judicial de la República Oriental del Uruguay: intervención con principios de diseño centrado en el usuario",
          "Poder Judicial de la República Oriental del Uruguay: implementación de intervenciones",
        ]}
      />
      <Slide
        setBack={setBack}
        icon={icon211}
        prev="#/2.1/Liderazgo para la transformación"
        titulo="Planificación del proceso"
        subtitulo="Identificación de actores clave"
        numSubtitulo="2.1.1"
        cuerpo="Algunos poderes judiciales y expertos en la materia recalcan la necesidad de incluir funcionarios administrativos, legisladores, representantes del Poder Ejecutivo, líderes de los principales colegios de abogados, representantes de la comunidad, de la defensoría pública y fiscalía, policías y mediadores, así como representantes de otros sectores de la profesión jurídica que se puedan ver afectados por los procesos de transformación. Adicionalmente, es recomendable contar con el apoyo de especialistas en planeación estratégica y expertos que hayan estado involucrados en procesos de transformación similares. El éxito del proceso dependerá del apoyo de todos los actores clave y del consenso logrado sobre la manera de llevar a cabo la transformación"
        mensaje={{
          icono: "",
          texto:
            "El NCSC (National Cyber Security Centre o Centro Nacional de Seguridad Cibernética) sugiere que este grupo incluya a una persona externa por cada dos o tres personas internas al Poder Judicial",
        }}
      />

      <Slide
        setBack={setBack}
        icon={icon212}
        prev="#/2.1/Liderazgo para la transformación"
        titulo="Planificación del proceso"
        subtitulo="Reuniones de trabajo"
        numSubtitulo="2.1.2"
        cuerpo="Una vez que se han identificado a los actores clave es necesario convocarlos a una serie de reuniones de trabajo para planear el proceso. Para ello, es importante que sea la máxima autoridad del Poder Judicial quien los convoque a fin de asegurar su participación."
      />

      <Slide
        setBack={setBack}
        icon={icon213}
        prev="#/2.1/Liderazgo para la transformación"
        titulo="Planificación del proceso"
        subtitulo="Visión"
        numSubtitulo="2.1.3"
        cuerpo={[
          "Durante el desarrollo de proyectos grandes como el de transformación digital, es muy fácil perder la visión, el objetivo. Es importante fijar, mantener y acordar una visión. Una buena práctica para no perder de vista la visión y los objetivos es que el grupo de actores clave plantee una serie de principios que guíen los esfuerzos que se llevarán a cabo y que conecten la misión de la corte con los proyectos o intervenciones específicas.",
          "En este sentido, cualquier decisión, proyecto o intervención es evaluada de acuerdo con el cumplimiento de estos principios, los cuales pueden dividirse en tres:",
          "a) Principios de gobernanza que estandaricen la toma de decisiones",
          "b) principios para la administración de casos que definan de qué manera se maneja cada uno de los asuntos",
          "c) principios relacionados con las funciones esenciales del Poder Judicial, que ayuden a justificar la reasignación de presupuesto.",
        ]}
      />
    </>
  );
}
