/* eslint-disable complexity */
import React from "react";
import { Modal, Box, Typography, Grid } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import countryCodesJson from "../utils/countryCodes.json";
import audienciaFactibilidad from "../utils/iconosModalNube/audienciaFactibilidad.png";
import capacitacionActoresInternosExternos from "../utils/iconosModalNube/capacitacionActoresInternosExternos.png";
import exhortosElectronicos from "../utils/iconosModalNube/exhortosElectronicos.png";
import expedienteElectronico from "../utils/iconosModalNube/expedienteElectronico.png";
import firmaDigitalUniversal from "../utils/iconosModalNube/firmaDigitalUniversal.png";
import firmaElectronicaCertificada from "../utils/iconosModalNube/firmaElectronicaCertificada.png";
import habilitacionCuentasCorreo from "../utils/iconosModalNube/habilitacionCuentasCorreo.png";
import juiciosLinea from "../utils/iconosModalNube/juiciosLinea.png";
import plataformaConsultaEstatus from "../utils/iconosModalNube/plataformaConsultaEstatus.png";
import prerregistroDemandasSistemaCitas from "../utils/iconosModalNube/prerregistroDemandasSistemaCitas.png";
import presentacionDemandasLinea from "../utils/iconosModalNube/presentacionDemandasLinea.png";
import protocolosRealizacionAudiencias from "../utils/iconosModalNube/protocolosRealizacionAudiencias.png";
import publicidadAudienciasRemotas from "../utils/iconosModalNube/publicidadAudienciasRemotas.png";
import resolucionConflictos from "../utils/iconosModalNube/resolucionConflictos.png";
import sistemaBusquedaInformacionJudicial from "../utils/iconosModalNube/sistemaBusquedaInformacionJudicial.png";
import sistemaGeorreferenciacion from "../utils/iconosModalNube/sistemaGeorreferenciacion.png";
import sistemaGestionAutomatizados from "../utils/iconosModalNube/sistemaGestionAutomatizados.png";
import sistemaGestionPenal from "../utils/iconosModalNube/sistemaGestionPenal.png";
import transferenciasRecepcionPagoPensiones from "../utils/iconosModalNube/transferenciasRecepcionPagoPensiones.png";

const iconosModal = {
  "Audiencia de factibilidad": audienciaFactibilidad,
  "Capacitación a actores internos y externos que participan en juicios sobre plataformas y lineamientos":
    capacitacionActoresInternosExternos,
  "Exhortos electrónicos con la finalidad de comunicar resoluciones y hacer requerimientos":
    exhortosElectronicos,
  "Expediente electrónico": expedienteElectronico,
  "Firma Digital Universal": firmaDigitalUniversal,
  "Firma electrónica avanzada": firmaElectronicaCertificada,
  "Habilitación de cuentas de correo electrónico, números de fax y direcciones de\ncorreo postal para recibir demandas y promociones":
    habilitacionCuentasCorreo,
  "Juicios en línea": juiciosLinea,
  "Plataformas de consulta del estatus de los asuntos":
    plataformaConsultaEstatus,
  "Prerregistro de las demandas y sistema de citas para depositarlas":
    prerregistroDemandasSistemaCitas,
  "Presentación de demandas en línea": presentacionDemandasLinea,
  "Protocolos para la realización de audiencias orales por medios tecnológicos":
    protocolosRealizacionAudiencias,
  "Publicidad de audiencias remotas": publicidadAudienciasRemotas,
  "Resolución de conflictos en línea": resolucionConflictos,
  "Sistemas de Búsqueda de Información Judicial":
    sistemaBusquedaInformacionJudicial,
  "Sistemas de georreferenciación para actividades actuariales":
    sistemaGeorreferenciacion,
  "Sistemas de gestión automatizados": sistemaGestionAutomatizados,
  "Sistema de gestión penal para el registro de asistencia de los inculpados que gozan del beneficio de libertad bajo caución":
    sistemaGestionPenal,
  "Transferencias para recepción y pago de pensiones":
    transferenciasRecepcionPagoPensiones,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 1,
  borderRadius: "5px",
};

export const BuenaPracticaModal = (props) => {
  const { open, handleClose, buenaPractica } = props;
  const ejemplos = buenaPractica.ejemplos;

  const getCountryCode = (pais) => {
    const countryCode = Object.keys(countryCodesJson).find(
      (key) => countryCodesJson[key] === pais
    );
    return countryCode;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {buenaPractica ? (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              border: "8px solid #01a29e",
              borderRadius: "5px",
              maxWidth: "500px",
              rowGap: "40px",
              padding: "15px",
            }}
          >
            <Grid item container>
              <Grid item sm={9} sx={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {buenaPractica.nombre}
                </h1>
              </Grid>
              <Grid item sm={3}>
                <img
                  src={iconosModal[buenaPractica.nombre]}
                  className="imgBuenaPractica"
                />
              </Grid>
            </Grid>
            <Grid item className="explicacionBuenaPractica scroll" sx={{textAlign: "justify"}}>
              {buenaPractica.explicacion}
              {buenaPractica.link ? null : (
                <p style={{ fontStyle: "italic", marginTop: "10px" }}>
                  {
                    "\n Esta página se encuentra temporalmente desactivada ya que el TEPJF ha decidido ampliar este ejercicio e incluir información de todas las ponencias en una nueva página que actualmente está en construcción."
                  }
                </p>
              )}
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "bold", marginBottom: "20px" }}>
                Buenas prácticas:
              </Typography>
              <div className="containerGoodPractices scroll">
                {ejemplos
                  ? ejemplos.map((ejemplo, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginLeft: "30px",
                          }}
                        >
                          <img
                            src={`https://flagcdn.com/h60/${getCountryCode(
                              ejemplo.pais
                            )}.png`}
                            alt={ejemplo.pais}
                            title={ejemplo.pais}
                            style={{ border: "4px solid #01a29e" }}
                          />
                          <a
                            href={ejemplo.link ? ejemplo.link : null}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginLeft: "10px", fontSize: "16px" }}
                          >
                            {ejemplo.nombre + ". " + ejemplo.pais}
                            <span>
                              {ejemplo.estado ? ", " + ejemplo.estado : ""}
                            </span>
                          </a>
                        </div>
                      );
                    })
                  : null}
              </div>
            </Grid>
          </Grid>
        ) : (
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"center"}
            alignItems="center"
          >
            <ConstructionIcon color="primary" />
            <Typography>
              Lo sentimos, aún estamos trabajando en esta ficha técnica
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default BuenaPracticaModal;
