export const poderes = [
  { idPoder: "MX.AG", name: "Aguascalientes" },
  { idPoder: "MX.BC", name: "Baja California" },
  { idPoder: "MX.BS", name: "Baja California Sur" },
  { idPoder: "MX.CH", name: "Chihuahua" },
  { idPoder: "MX.CS", name: "Chiapas" },
  { idPoder: "MX.CM", name: "Campeche" },
  { idPoder: "MX.DF", name: "Ciudad de México" },
  { idPoder: "MX.CO", name: "Coahuila" },
  { idPoder: "MX.CL", name: "Colima" },
  { idPoder: "MX.DG", name: "Durango" },
  { idPoder: "MX.GR", name: "Guerrero" },
  { idPoder: "MX.GJ", name: "Guanajuato" },
  { idPoder: "MX.HG", name: "Hidalgo" },
  { idPoder: "MX.JA", name: "Jalisco" },
  { idPoder: "MX.MI", name: "Michoacán" },
  { idPoder: "MX.MX", name: "Estado de México" },
  { idPoder: "MX.MO", name: "Morelos" },
  { idPoder: "MX.NA", name: "Nayarit" },
  { idPoder: "MX.NL", name: "Nuevo León" },
  { idPoder: "MX.OA", name: "Oaxaca" },
  { idPoder: "MX.PU", name: "Puebla" },
  { idPoder: "MX.QR", name: "Quintana Roo" },
  { idPoder: "MX.QT", name: "Querétaro" },
  { idPoder: "MX.SI", name: "Sinaloa" },
  { idPoder: "MX.SL", name: "San Luis Potosí" },
  { idPoder: "MX.SO", name: "Sonora" },
  { idPoder: "MX.TB", name: "Tabasco" },
  { idPoder: "MX.TL", name: "Tlaxcala" },
  { idPoder: "MX.TM", name: "Tamaulipas" },
  { idPoder: "MX.VE", name: "Veracruz" },
  { idPoder: "MX.YU", name: "Yucatán" },
  { idPoder: "MX.ZA", name: "Zacatecas" },
];