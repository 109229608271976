import React, { useEffect, useState } from "react";
import { Geography } from "react-simple-maps";
import estilosMapa from "../../utils/styleGeograpy";
import ModalMapa from "../ModalMapa";

export default function Estado(props) {
  const { geo, setTooltip, optionState, clickEntidad, herramienta, ficha, entidadesAct } = props;
  const [color, setColor] = useState("");
  const [open, setOpen] = useState(false);
  const [entidadFed, setEntidadFed] = useState("");

  useEffect(() => {
    if (herramienta === "Comparador") {
      iluminacionMapa();
    } else validacion();
  }, [ficha, entidadesAct]);

  const validacion = () => {
    ficha ? setColor("#03989E") : cambioColor();
  };

  const cambioColor = () => {
    ficha === geo.id ? setColor("#94D4C4") : setColor("#03989E");
  };

  const iluminacionMapa = () => {
    entidadesAct.find((idEnt) => idEnt === geo.id)
      ? setColor("#913E09")
      : setColor("#03989E");
  };

  return (
    <>
      <Geography
        geography={geo}
        onMouseEnter={() => {
          setTooltip(geo.properties.name);
          if (herramienta === "Ficha") optionState(geo);
        }}
        onMouseLeave={() => {
          setTooltip("");
        }}
        style={estilosMapa(color)}
        onClick={() => {
          if (herramienta === "Ficha") clickEntidad(geo);
          setEntidadFed(geo.properties.name)
          setOpen(true);
        }}
      />
      <ModalMapa 
        open={open} 
        handleClose={() => setOpen(false)} 
        ficha={geo.id} 
        entidadFed={entidadFed}
        setEntidadFed={setEntidadFed} 
      />
    </>
  );
}
