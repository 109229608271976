export const juiLin = [
    [
        { name: "Cuenta con juicios en línea", tam: 1 },
        { name: "Nombre del juzgado virtual 1", tam: 1 },
        { name: "Tipo de procedimientos que se pueden realizar en línea", tam: 1 },
        { name: "Nombre del juzgado virtual 2", tam: 1 },
        { name: "Tipo de procedimientos que se pueden realizar en línea", tam: 1 },
        { name: "Nombre del juzgado virtual 3", tam: 1 },
        { name: "Tipo de procedimientos que se pueden realizar en línea", tam: 1 },
        { name: "Nombre del juzgado virtual 4", tam: 1 },
        { name: "Tipo de procedimientos que se pueden realizar en línea", tam: 1 },
        { name: "Año de implementación", tam: 1 },
    ]
];