import React from "react";
import Slide from "../Slide";

import icon36 from "../icons/icon3.6.png";
import icon361 from "../icons/icon3.6.1.png";
import icon362 from "../icons/icon3.6.2.png";
import icon363 from "../icons/icon3.6.3.png";
import icon364 from "../icons/icon3.6.4.png";

export default function SubTemaTresSeis(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        prev="#/3.0/Diseño centrado en el usuario"
        subtemas={[
          { texto: "Indicadores para evaluar impacto", num: "3.6.1" },
          { texto: "Encuestas a personas usuarias", num: "3.6.2" },
          { texto: "Boleta de calificación ciudadana", num: "3.6.3" },
          { texto: "Pruebas aleatorias controladas", num: "3.6.4" },
        ]}
        icon={icon36}
        titulo="Evaluación"
        subtitulo="Evaluación"
        numSubtitulo="3.6"
        cuerpo="La evaluación a corto plazo permite analizar si la intervención o herramienta tiene el impacto esperado, la evaluación a largo plazo permite entender los efectos posteriores a la intervención, tanto en el sistema como para las personas y sus comunidades."
        casosDeEstudio={[
          "Ministerio de Justicia de España: indicadores para evaluar intervenciones",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/3.6/Evaluación"
        icon={icon361}
        titulo="Evaluación"
        subtitulo="Indicadores para evaluar impacto"
        numSubtitulo="3.6.1"
        cuerpo="Los indicadores son un medio simple y confiable para medir el logro. Es importante tomar en cuenta todos aquellos que están relacionados con el nivel de uso, su impacto en términos de tiempos, costos, y satisfacción de las y los usuarios, entre otros aspectos."
      />
      <Slide
        setBack={setBack}
        prev="#/3.6/Evaluación"
        icon={icon362}
        titulo="Evaluación"
        subtitulo="Encuestas a personas usuarias"
        numSubtitulo="3.6.2"
        cuerpo="Este instrumento permite conocer las experiencias de las y los usuarios, su nivel de satisfacción, dar espacio para retroalimentación. Una ventaja es la flexibilidad para incluir diversos tipos de preguntas. "
      />
      <Slide
        setBack={setBack}
        prev="#/3.6/Evaluación"
        icon={icon363}
        titulo="Evaluación"
        subtitulo="Boleta de calificación ciudadana"
        numSubtitulo="3.6.3"
        cuerpo="Este método permite medir las percepciones de las y los usuarios respecto de la calidad, eficiencia y la idoneidad de los servicios públicos. Se compone de tres partes; una encuesta, entrevistas complementarias y diseminación de resultados."
      />
      <Slide
        setBack={setBack}
        prev="#/3.6/Evaluación"
        icon={icon364}
        titulo="Evaluación"
        subtitulo="Pruebas aleatorias controladas"
        numSubtitulo="3.6.4"
        cuerpo="Este tipo de pruebas se utilizan para conocer el impacto de una intervención en una población específica, comparando el grupo que recibe la intervención con uno que no la recibe, esto para asegurarse de que los cambios se deben realmente a la intervención."
      />
    </>
  );
}
