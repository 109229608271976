export const heads = [
  "#",
  "Nombre de buena práctica",
  "explicacion",
  "ejemplos",
  "pais",
  "estado",
  "link",
  "CostaRica",
  "Canada",
  "Chile",
  "Mexico",
  "Argentina",
  "Australia",
  "Colombia",
  "Sentencias Resoluciones",
  "Resolucion de conflictos en linea",
  "Plataformas electronicas",
  "Inteligencia artificial IAAI",
  "Mecanismos alternativos para la resolucion de conflictos MASC",
  "Presentacion de demandas en linea",
  "Presentacion de promociones en linea",
  "Identificacion de personas",
  "Seguridad",
  "Tramite judicial",
  "Sistema de citas",
  "Grado de avance sobre el proceso",
  "Comunicacion entre juzgado y partes",
  "Comunicacion entre juzgados",
  "Comunicacion interna del juzgado",
  "Audiencias",
  "Transparencia",
  "Capacitacion",
  "Protocolos",
  "Sistematizacion de informacion",
  "Actuarios",
  "Indicadores",
  "Busqueda de informacion",
  "Ejecucion de sentencias",
  "Datosabiertos",
  "Exhortos",
  "Firma electronica avanzada",
  "Firma Digital universal",
  "Expediente electronico",
  "Videoconferencias",
  "Lineamientos",
  "Alertas del vencimiento de los plazos en un proceso",
  "Georreferenciacion",
  "Buscadores de sentencias / jurisprudencia / legislacion",
  "Envio y recepcion de pagos de pension",
  "Derecho penal",
  "Sistema de gestion penal",
  "Registro de asistencia",
  "Libertad bajo caucion",
  "Notificacion a las partes",
  "Aviso de privacidad",
  "Celebracion de actos y contratos electronicamente",
  "Trabajo a distancia",
  "Proteccion de derechos",
  "Salas espejos de audiencia virtual",
  "Justicia abierta",
  "Publicidad de audiencias remotas",
  "Sistema de getsion",
  "Juicios en linea",
  "oficina judicial / oficialia de partes / tribunal electronico",
  "Prerregistro de demandas",
  "Cosmos",
  "Oficina Judicial Virtual",
  "Actuarius",
  "Juzgados en linea",
  "Lenguaje claro",
  "TEPJF",
  "Derechos politico electorales",
];
