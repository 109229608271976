/* eslint-disable no-magic-numbers */
import React from "react";
import ResultsConsulta from "./consulta/ResultsConsulta";
import { Box, AppBar, Toolbar } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Stack } from "@mui/system";
import Toast from "../../utils/Alert";
import { BtnDownload } from "./Buttons";
import excelCreator from '../../utils/excelCreator'

const drawerWidth = 350;

export const ComparatorContent = styled('main', {  shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: '100%',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export default function ComparadorTecDig(props) {
  const { setComparadores, searchResults, showLoader, toastOptions, showToast, setShowToast } = props;

  return (
    <>
    <AppBar position="static">
      <Toolbar sx={{ backgroundColor: '#FFF' }}>
        <Stack spacing={2} direction="row">
          <BtnDownload texto={"Descargar"} onClick={() => excelCreator(searchResults)} />
        </Stack>
      </Toolbar>
    </AppBar>
      <Box sx={{ display: 'flex', height: '85%', flexDirection: 'column' }}>
        <ResultsConsulta 
          setComparadores={setComparadores} 
          searchResults={searchResults} 
          showLoader={showLoader}
          />
      </Box>
      <Toast open={showToast} message={toastOptions.message} severity={toastOptions.severity} setOpen={setShowToast} />
    </>
  );
}
