import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { poderes } from "../../../utils/poderes";

export default function ListPoderes(props) {
  const { addFiltro, cleanFilters } = props;
  const [autocompleteValues, setAutocompleteValues] = React.useState([]);

  React.useEffect(() => {
    if (cleanFilters) setAutocompleteValues([])
  }, [cleanFilters]);

  return (
    <Stack spacing={3} sx={{ "pl": 2, "pr": 2}}>
      <Autocomplete
        value={autocompleteValues}
        multiple
        id="tags-standard"
        options={poderes}
        limitTags={2}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Poderes Judiciales" placeholder="Buscar"/>
        )}
        onChange={(evento, valores) => {
          setAutocompleteValues(valores);
          addFiltro('poderJudicial', '', valores.map(valor => valor.name), true);
        }}
      />
    </Stack>
  );
}
