import React from "react";
import Implementacion from "./Implementacion";
import ListFiltros from "./ListFiltros";
import { set, get, unset, update } from 'lodash';
import { filtros } from "../../../utils/filtros";
import ListPoderes from "./ListPoderes";
import { getHerramientasPorEstado } from "../../../utils/api";

const h3Style = { color: "black", marginBottom: "0.5rem", fontSize: "18px" };

export default function MenuFiltros(props) {
  const { setSearchResults, setShowLoader, cleanFilters, setCleanFilters, setShowToast, setToastOptions, setFiltrosAct } = props;

  React.useEffect(() => {
    if (cleanFilters) setFiltrosAct({});
  }, [cleanFilters])
  
  // eslint-disable-next-line complexity
  const buildPath = (grandParentFilter, parentFilter, filter) => {
    const blackList = ['si', 'no', 'enDesarrollo', 'ver'];
    const equalities = {'si': '1', 'no': '0', 'ver': true, 'enDesarrollo': 'En desarrollo'};
    const parseFilter = (filter) => equalities.hasOwnProperty(filter) ? equalities[filter] : filter ;
    let path = [];
  
    if(grandParentFilter === 'poderJudicial') path = ['poderJudicial', filter]
    else if(!grandParentFilter) path = [parentFilter + '.' + filter, '1']
    else path = blackList.includes(filter) ? [grandParentFilter + '.' + parentFilter, parseFilter(filter)] : [grandParentFilter + '.' + parentFilter + '.' + filter, '1']
  
    return path; 
  }
  
  // eslint-disable-next-line complexity
  const buildFilters = (currentFilters, grandParentFilter, parentFilter, filter, checkboxValue) => {
    const filters = currentFilters;
    const [path, value] = buildPath(grandParentFilter, parentFilter, filter);
    const valueAtPath = get(filters, path);
  
    if(path === 'poderJudicial') set(filters, path, value)
    else {
      if(!valueAtPath && checkboxValue) {
        set(filters, path, value)
      }
      if(valueAtPath && !checkboxValue) {
        if(Array.isArray(valueAtPath)) {
          const newValue = valueAtPath.filter(currentValueInPath => currentValueInPath !== value);
          set(filters, path, newValue)
        } else {
          unset(filters, path, filter)
        }
      }
      if(valueAtPath && checkboxValue) {
        update(filters, path, (currentValue) => [...currentValue, value])
      }
    }

    buscar(filters)
  
    return filters;
  }

  const addFiltro = (grandParentFilter, parentFilter, filtro, activado) => {
    setCleanFilters(false);
    setFiltrosAct((currentFilters) => {
      return buildFilters(currentFilters, grandParentFilter, parentFilter, filtro, activado)
    })
  };

  const buscar = async (filtros) => {
    setShowLoader(true);
    getHerramientasPorEstado(filtros)
      .then(data => {
        setSearchResults(data?.data?.data?.results);
        setShowLoader(false)
      })
      .catch((error) => {
        setShowToast(true)
        setToastOptions({message: error.response.data.message === 'Empty body' ? 'No se seleccionó ningún filtro' : error.response.data.message , severity: 'error'});
        setShowLoader(false);
      })
  };

  return (
    <>
      <nav className="navFiltros">
        <h3 style={{ ...h3Style, fontSize: "20px", textAlign: "center" }}>
          Filtros
        </h3>
        <ListPoderes addFiltro={addFiltro} cleanFilters={cleanFilters} />
        <div className="filtros scroll" >
          {Object.entries(filtros).map((filtro) => {
            const [key, value] = filtro;
            return (
              <ListFiltros
                key={key}
                valores={value}
                addFiltro={addFiltro}
                encabezado={key}
                cleanFilters={cleanFilters}
              />
            );
          })}
          <Implementacion />
        </div>
      </nav>
    </>
  );
}
