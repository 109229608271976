/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import PoderesActivos from "./PoderesActivos";
import { logos } from "../../../utils/LogosPoderes";
import { poderes } from "../../../utils/poderes";
import Loader from '../../../utils/Loader'
import { Help } from "@mui/icons-material";
import { useLabel } from "../../../../shared/CustomUtilHooks";
import { Typography } from "@mui/material";

const ONCE = 1;

export default function TablaConsulta(props) {
  const { searchResults, setComparadores, showLoader } = props;
  const [expanded, setExpanded] = useState(false);
  const createLabel = useLabel();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if(searchResults.length > 0){
      const aux = []
      searchResults.map(resultado => {
        const poder = poderes.find(item => item.name === resultado.poderJudicial)
        //console.log(poder);
        aux.push(poder);
      })
      setComparadores(aux);
    }
  }, [searchResults]);

  const getLogo = (name) => {
    const poder = poderes.find(item => item.name === name);
    const logoUrl = logos[poder.idPoder];
    return logoUrl;
  };

  createLabel('', '1', 0);

  return showLoader ? <Loader /> : (
    <>
      <Typography sx={{ marginTop: '10px' }}>{createLabel('comparador.resultsFound', searchResults.length, searchResults.length === ONCE ? 'resultado' : 'resultados')}</Typography>
      {searchResults.length > 0 ? (
      <div className="resultsComparador scroll">
        {searchResults.map((poderJud, i) => {
          const name = poderJud.poderJudicial;
          const logoUrl = getLogo(name);
          return (
            <PoderesActivos
              key={i}
              dataPoder={poderJud}
              name={name}
              logoUrl={logoUrl}
              index={i}
              handleChange={handleChange}
              expanded={expanded}
            />
          );
        })}
      </div>
    ) : (
      <div className="mensajeComparador">
        <Help color="info" fontSize="large" />
        <h3> { createLabel('comparador.noFiltersSelected') } </h3>
      </div>
    )}
    </>
  );
}
