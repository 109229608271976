/* eslint-disable complexity */
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Card } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import BuenaPracticaModal from "./ModalBuenaPractica";

const MIN_SLIDES = 3;

const slide = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const card = (index) => {
  /* eslint-disable no-magic-numbers */
  if (index % 2 === 0) {
    return {
      background: "#00868D",
      color: "white",
    };
  } else {
    return {
      background: "#94D4C4",
      color: "black",
    };
  }
};

export default function ResumeGoodPractices(props) {
  const { buenasPracticas } = props;
  const [open, setOpen] = React.useState(false);
  const [buenaPractica, setBuenaPractica] = React.useState({})
  
  useEffect(() => {}, [buenasPracticas, buenasPracticas.length]);

  const responsive = (num) => {
    if (buenasPracticas.length > MIN_SLIDES) {
      return num;
    } else {
      return (buenasPracticas.length > num) ?  num : buenasPracticas.length;
    }
  }

  return (
    <>
      <Swiper
        slidesPerView={
          buenasPracticas.length > MIN_SLIDES
            ? MIN_SLIDES + 1
            : buenasPracticas.length
        }
        spaceBetween={10}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper resumeGoodPractices"

        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: responsive(1),
          },
          1024: {
            slidesPerView: responsive(2),
          },
          1440: {
            slidesPerView: responsive(3),
          },
          2560: {
            slidesPerView: responsive(4),
          },
        }}
      >
        {buenasPracticas.map((buenaPractica, index) => (
          <SwiperSlide style={slide} key={index}>
            <Card sx={card(index)} className="card"
                  onClick={() => {
                    setBuenaPractica(buenaPractica) 
                    setOpen(true)
                  }} 
            >
              {buenaPractica.nombre}
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
        <BuenaPracticaModal open={open} buenaPractica={buenaPractica} handleClose={() => setOpen(false)} />
    </>
  );
}