import React from "react";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab"
import DownloadIcon from "@mui/icons-material/Download";
import { ThemeProvider } from "@mui/system";
import { theme } from "../../utils/themeMUI";
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export default function Buttons(props) {
  const { texto } = props;
  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" fullWidth={false}>
        {texto}
      </Button>
    </ThemeProvider>
  );
}

export function BtnDownload(props) {
  const { texto, href, onClick } = props;

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        color="success"
        href={href}
        endIcon={<DownloadIcon />}
        fullWidth={false}
        sx={{width:"150px", margin:"0 auto"}}
        style={{justifyContent:"space-between"}}
        onClick={onClick}
      >
        {texto}
      </Button>
    </ThemeProvider>
  );
}

export function BtnDownloadFicha(props) {
  const { texto, href } = props;

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        color="success"
        href={href}
        endIcon={<DownloadIcon />}
        fullWidth={false}
      >
        {texto}
      </Button>
    </ThemeProvider>
  );
}

export function BtnCleanSearch(props) {
  const {texto, onClick} = props
  return (
    <ThemeProvider theme={theme}>
      <Button
        sx={{width:"fit-content", margin:"0 auto"}}
        variant="outlined"
        color="red-signal"
        endIcon={<SearchOffIcon />}
        onClick={onClick}
        fullWidth={false}
      >
        {texto}
      </Button>
    </ThemeProvider>
  )
}

export function BtnBuscar(props) {
  const { texto, onClick, showLoader } = props;
  return (
    <ThemeProvider theme={theme}>
      <LoadingButton
        color="info-2"
        onClick={onClick}
        loading={showLoader}
        loadingPosition="end"
        endIcon={<SearchIcon />}
        variant="contained"
        sx={{width:"150px", margin:"0 auto"}}
        style={{justifyContent:"space-between"}}
      >
        {texto}
      </LoadingButton>
    </ThemeProvider>
  );
}

