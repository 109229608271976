/* eslint-disable complexity */
import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { Button } from "@mui/material";
import Highlighter from "react-highlight-words";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

export default function CasoDeEstudio(props) {
  const { nombre, temas, textoCompleto, back} = props;
  const [textoResaltado, setTextoResaltado] = React.useState({
    texto: [""],
    color: "yellow",
  });

  return (
    <section id={nombre?.trim()}>
      <div className="slide scroll">
        <div className="house">
          <a href="#0/0">
            <HomeIcon sx={{ fontSize: "2rem", color: "#00a399" }} />
          </a>
          <a href={back ? back : "#/Menu-Casos-Estudio"}>
            <ReplyAllIcon sx={{ fontSize: "2rem", color: "#00a399" }} />
          </a>
        </div>
        <div className="casoEstudioSlide">
          <h1 className="tituloCasosEstudio">Casos de estudio</h1>
          <h5 className="titleCasoEstudio">{nombre}</h5>
          <div className="cuerpoCasoEstudio">
            <div className="botonesCasoEstudio">
              {temas
                ? temas.map((tema, i) => {
                    return (
                      <Button
                        onClick={() => {
                          setTextoResaltado(
                            textoResaltado.texto[0] === tema.texto[0] &&
                              textoResaltado.color === tema.color
                              ? { texto: [""], color: "yellow" }
                              : tema
                          );
                        }}
                        key={i}
                        sx={{
                          cursor: "pointer",
                          fontSize: "0.5rem",
                          color: "black",
                          textTransform: "none",
                          padding: "4px 1rem",
                          backgroundColor: tema.color,
                          "&:hover": {
                            color: "white",
                            backgroundColor: tema.color,
                            transform: "scale(1.1)",
                          },
                        }}
                      >
                        {tema.titulo}
                      </Button>
                    );
                  })
                : null}
            </div>
            <div className="cuerpoTextoCasoEstudio scroll">
              <Highlighter
                searchWords={textoResaltado.texto}
                textToHighlight={textoCompleto}
                autoEscape={true}
                highlightStyle={{ backgroundColor: textoResaltado.color }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
