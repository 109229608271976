export const capImp = [
    [
      { name: "Capacitación sobre firma electrónica", tam: 3 },
      { name: "Capacitación sobre sistema de gestión", tam: 2 },
      { name: "Capacitación sobre expediente electrónico", tam: 3 },
      { name: "Plataformas de envío y recepción de demandas", tam: 3 },
      {
        name: "Capacitación sobre realización de audiencias por videoconferencia",
        tam: 3,
      },
      { name: "Capacitación sobre búsqueda y consulta de sentencias", tam: 2 },
      {
        name: "Capacitación sobre herramienta de elaboración pública de sentencias",
        tam: 3,
      },
    ],
    [
      { name: "Cuenta con firma electrónica", tam: 1 },
      { name: "Usuarios internos", tam: 1 },
      { name: "Usuarios externos", tam: 1 },
      { name: "Sistema de gestión", tam: 1 },
      { name: "Usuarios internos", tam: 1 },
      { name: "Cuenta con expediente electrónico", tam: 1 },
      { name: "Usuarios internos", tam: 1 },
      { name: "Usuarios externos", tam: 1 },
      { name: "Plataformas de envío y recepción de demandas", tam: 1 },
      { name: "Usuarios internos", tam: 1 },
      { name: "Usuarios externos", tam: 1 },
      { name: "Realización de audiencias por videoconferencias", tam: 1 },
      { name: "Usuarios internos", tam: 1 },
      { name: "Usuarios externos", tam: 1 },
      { name: "Plataforma de búsqueda y consulta de sentencias", tam: 1 },
      { name: "Usuarios internos", tam: 1 },
      { name: "Usuarios externos", tam: 1 },
      { name: "Herramienta para elaboración pública de sentencias", tam: 1 },
      { name: "Usuarios internos", tam: 1 },
    ],
  ];