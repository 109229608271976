import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import {YearPicker} from '@mui/x-date-pickers/YearPicker';
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ListItem } from "@mui/material";

export default function Implementacion() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <List>
        <ListItemButton onClick={handleClick}>
          <ListItemText>
            <h3 style={{ fontSize: 16 }}>Año de implementacion</h3>
          </ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ "pl": 6 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{}}>
                <YearPicker
                  justifyContent="center"
                  alignItems="center"
                  onChange={(cambio) => {
                    console.log(cambio.$y);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  disableFuture={true}
                  maxDate={new Date()}
                  minDate={new Date("2000/01/01")}
                />
              </LocalizationProvider>
            </ListItem>
          </List>
        </Collapse>
      </List>
    </div>
  );
}
