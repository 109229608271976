/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import React from "react";

const getStyle = (tipo) => {
  if (tipo === 1) return "encabezado";
  if (tipo === 2) return "subtitulo-1";
  if (tipo === 3) return "subtitulo-2";
  if (tipo === 4) return "subTemaPrincipalSlide";

  return "subtitulo-1";
};

export default function TemaProceso(props) {
  const { texto, tipo, num, href, rename, top, setBack } = props;
  /* eslint-disable no-magic-numbers */
  return (
    <a
      href={href ? "#/" + href + "/" + texto?.trim() : null}
      className={getStyle(tipo)}
      onClick={() => {
        setBack
          ? setBack(href ? "#/" + href + "/" + texto?.trim() : null)
          : null;
      }}
    >
      {num && tipo === 1 ? <div className="numFase">{num}</div> : null}
      {top ? <div className="lineaDiagramaTema"></div> : null}
      <span style={{ color: "white" }}>{num && tipo !== 1 ? num : null}</span>
      {rename ? rename : texto}
    </a>
  );
}
