/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
// import Reveal from "reveal.js";
import React from "react";
import TemaProceso from "./TemaProceso";
import ListCards from "./ListCards";
import HomeIcon from "@mui/icons-material/Home";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

export default function Slide(props) {
  const {
    subtemas,
    icon,
    titulo,
    subtitulo,
    numSubtitulo,
    cuerpo,
    cards,
    typeListCard,
    casosDeEstudio,
    mensaje,
    listCuerpo,
    iconList,
    typeList,
    secondCuerpo,
    secondHeader,
    prev, setBack
  } = props;

  return (
    <section id={numSubtitulo + "/" + subtitulo?.trim()}>
      <div className="slide-Diagrama scroll">
        <div href="" className="house">
          <a href="#0/0"> <HomeIcon sx={{ fontSize: "2rem", color: "#00a399" }} onClick={() => {setBack("")}} /></a>
          <a href={prev}><ReplyAllIcon sx={{ fontSize: "2rem", color: "#00a399" }} onClick={() => {setBack(prev)}} /></a>
        </div>
        <div className="header">
          <img src={icon} />
          <div className="encabezados">
            <h2 className="title">{titulo}</h2>
            <h3 className="subTitle">
              <span>{numSubtitulo ? numSubtitulo : null}</span>
              {subtitulo}
            </h3>
          </div>
        </div>

        <div className="cuerpo">
          {Array.isArray(cuerpo)
            ? cuerpo.map((parafo, index) => <p key={index}>{parafo}</p>)
            : cuerpo}

          {listCuerpo ? (
            <div className={iconList ? "listCuerpo icon" : "listCuerpo"}>
              <img src={iconList ? iconList : null} alt="" />
              {typeList !== "number" ? (
                <ul>
                  {listCuerpo.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              ) : (
                <ol>
                  {listCuerpo.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ol>
              )}
            </div>
          ) : null}
        </div>
        {subtemas ? (
          <div className="subtemas">
            {subtemas.map((subtema, index) => {
              return (
                <TemaProceso
                  key={index}
                  texto={subtema.texto}
                  tipo={3}
                  num={subtema.num}
                  href={subtema.num}
                  rename={subtema.rename ? subtema.rename : null}
                  setBack={setBack ? setBack : null}
                />
              );
            })}
          </div>
        ) : null}

        {secondHeader ? <div className="encabezados">
          <h2 className="title-2">{secondHeader.titulo}</h2>
          <p className="cuerpo-2">{secondHeader.cuerpo}</p>
        </div> : null}

        {cards ? (
          <ListCards
            cards={cards}
            typeListCard={typeListCard ? typeListCard : 0}
          />
        ) : null}

        {secondCuerpo ? (
          <div className="secondCuerpo">
            <h3>{secondCuerpo.titulo}</h3>
            <p className="card-second-cuerpo">{secondCuerpo.cuerpo}</p>
            <img src={secondCuerpo.icon} alt="" />
          </div>
        ) : null}

        {mensaje ? (
          <div className="mensaje">
            <img src={mensaje.icono} alt="" />
            <p>{mensaje.texto}</p>
          </div>
        ) : null}

        {casosDeEstudio ? (
          <div className="casosDeEstudioWrapper">
            <div className="casosDeEstudio">
              {casosDeEstudio.map((casoDeEstudio, i) => {
                return (
                  <a
                    key={i}
                    href={"#/" + casoDeEstudio}
                    className="casoEstudioButton"
                  >
                    {casoDeEstudio}
                  </a>
                );
              })}
            </div>
            <a href={"#/Menu-Casos-Estudio"} className="casosEstudioButton">
              Casos de Estudio
            </a>
          </div>
        ) : null}
      </div>
    </section>
  );
}
