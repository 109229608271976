import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SubListFiltros from "./SubListFiltros";
import { useLabel } from "../../../../shared/CustomUtilHooks";

export default function ListFiltros(props) {
  const { valores, addFiltro, encabezado, cleanFilters } = props;
  const [open, setOpen] = React.useState(false);
  const createLabel = useLabel();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      <ListItemButton onClick={handleClick}>
        <ListItemText>
            <h3 style={{fontSize: 16}}>{ createLabel(encabezado) }</h3>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <SubListFiltros subFiltros={valores} addFiltro={addFiltro} parentFilter={encabezado} cleanFilters={cleanFilters} />
      </Collapse>
    </List>
  );
}
