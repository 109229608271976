/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import { Checkbox, Typography } from "@mui/material";
import React from "react";
import CasosEstudio from "../../utils/casosEstudio.png";
import HomeIcon from "@mui/icons-material/Home";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { temas } from "./Temas";

export default function SelectorCasosDeEstudio(props) {
  const { back } = props;
  const [subTemas, setSubTemas] = React.useState([]);

  const handleAddTopics = (casosEstudio, event) => {
    casosEstudio.forEach((casoEstudio) => {
      const indexElement = subTemas.findIndex(
        (subTema) => subTema === casoEstudio
      );

      if (event.target.checked) {
        if (indexElement === -1)
          setSubTemas((subTemasOld) => [...subTemasOld, casoEstudio]);
      } else {
        setSubTemas((subTemasOld) =>
          subTemasOld.filter((subTema) => subTema !== casoEstudio)
        );
      }
    });
  };

  return (
    <section>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center"}}
        className="selector"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <h1 className="tituloCasosEstudio">Casos de estudio</h1>
          <p className="indicacion">Selecciona el tema que deseas explorar:</p>
        </div>
        <img src={CasosEstudio} className="imgCasoEstudio" />
        <div href="" className="house" style={{top: "-0.8em"}}>
          <a href="#0/0" onClick={() => {setBack("")}}>
            <HomeIcon sx={{ fontSize: "2rem", color: "#00a399" }} />
          </a>
          {back ? <a href={back}>
            <ReplyAllIcon sx={{ fontSize: "2rem", color: "#00a399" }} />
          </a> : null}
        </div>
      </div>
      <div className="casosEstudioSlide">
        <div className="casosEstudioSelection scroll">
          {Object.keys(temas).map((tituloTema, i) => {
            return (
              <div key={i} className="casoEstudioItem">
                <Checkbox
                  onClick={(event) =>
                    handleAddTopics(temas[tituloTema].casosEstudio, event)
                  }
                />
                <div
                  className="casoEstudioItemTexto"
                  style={{
                    backgroundColor: temas[tituloTema].color,
                  }}
                >
                  {tituloTema}
                </div>
              </div>
            );
          })}
        </div>
        <Typography variant="p" className="subIndicacion">
          Casos de estudio relacionados:
        </Typography>
        <div className="seleccionCasosEstudio scroll">
          {subTemas.map((subTema, i) => {
            return (
              <a key={i} className="casoEstudio" href={"#/" + subTema} onClick={()=> setBack("#/Menu-Casos-Estudio")} >
                {subTema}
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
}
