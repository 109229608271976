import ComparadorTecDig from "./ComparadorTecDig";
import React from "react";

export default function TabuladorMapa(props) {
  const {
    setComparadores,
    searchResults,
    showLoader,
    toastOptions,
    showToast,
    setShowToast
  } = props;

  return (
    <div className="tabulador">
        <ComparadorTecDig 
          setComparadores={setComparadores} 
          searchResults={searchResults} 
          showLoader={showLoader} 
          toastOptions={toastOptions}
          showToast={showToast} 
          setShowToast={setShowToast} />
    </div>
  );
}
