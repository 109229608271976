import React from 'react'
import Tabla from './tablas/Tabla'

export default function InfoPoder(props) {
  const { dataPoder } = props;

  const confEquivalencias = (data) => {
    //eslint-disable-next-line complexity
    return data.map((texto) => {
      switch (texto) {
        case "1":
          return "Si"
        case "0":
          return "No"
        case "S/I":
          return "Sin información"
        case "N/A":
          return "No aplica"
        case "999":
          return "No proporcionó información"
        default:
          return texto 
      }
    })
  };

  return <>
    <Tabla info={dataPoder.infraestructuraTecnologica} cabecera="tabla.infraestructuraTecnologica" />
    <Tabla info={confEquivalencias(dataPoder.capacitacionImpartida)} cabecera="tabla.capacitacionImpartida" />
    <Tabla info={confEquivalencias(dataPoder.firmaElectronica)} cabecera="tabla.firmaElectronica" />
    <Tabla info={confEquivalencias(dataPoder.sistemaGestion)} cabecera="tabla.sistemaGestion" />
    <Tabla info={confEquivalencias(dataPoder.expedienteElectronico)} cabecera="tabla.expedienteElectronico" />
    <Tabla info={confEquivalencias(dataPoder.plataformaInterponerDemanda)} cabecera="tabla.plataformaInterponerDemanda" />
    <Tabla info={confEquivalencias(dataPoder.herramientaVideoconferencias)} cabecera="tabla.herramientaVideoconferencias" />
    <Tabla info={confEquivalencias(dataPoder.juiciosLinea)} cabecera="tabla.juiciosLinea" />
    <Tabla info={confEquivalencias(dataPoder.herramientasElaboracionPublicaSentencias)} cabecera="tabla.herramientasElaboracionPublicaSentencias" />
    <Tabla info={confEquivalencias(dataPoder.plataformaPublicacionSentencias)} cabecera="tabla.plataformaPublicacionSentencias" />
  </>
}
