import React, { useEffect, useState } from "react";
import Reveal from "reveal.js";
import Markdown from "reveal.js/plugin/markdown/markdown.esm.js";

import "../../../node_modules/reveal.js/dist/reset.css";
import "../../../node_modules/reveal.js/dist/reveal.css";
import "../../../node_modules/reveal.js/dist/theme/white.css";

import FaseProcesoUno from "./Fases/FaseProcesoUno";
import FaseProcesoDos from "./Fases/FaseProcesoDos";
import FaseProcesoTres from "./Fases/FaseProcesoTres";
import MenuCasosDeEstudio from "./casosDeEstudio/MenuCasosDeEstudio";
import MapaProcesos from "./Fases/MapaProcesos";

import "./estilos.css";

export default function DiagramWrapper() {
  document.title = "Diagrama Interactivo";
  useEffect(() => {
    let deck = new Reveal(document.getElementById("mapa-interactivo"), {
      plugins: [Markdown],
    });

    deck.initialize({
      controls: false,
      controlsTutorial: true,
      progress: false,
      keyboard: false,
      disableLayout: false,
    });
  }, []);

  const [back, setBack] = useState("");

  return (
    <div style={{ width: "100%", height: "100svh" }}>
      <div className="reveal" id="mapa-interactivo">
        <div className="slides" id="diagrama">
          <MapaProcesos setBack={setBack} />
          <FaseProcesoUno setBack={setBack} />
          <FaseProcesoDos setBack={setBack} />
          <FaseProcesoTres setBack={setBack} />
          <MenuCasosDeEstudio back={back} setBack={setBack} />
        </div>

        <script></script>
      </div>
    </div>
  );
}
