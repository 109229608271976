export default function estilosMapa(color) {
    const styleGeograpy = {
        default: {
          fill: color,
          outline: "none",
          stroke: "#fff",
          cursor: "pointer",
        },
        hover: {
          fill: "#1E6265",
          stroke: "#25888C",
          outline: "none",
          cursor: "pointer",
        },
        pressed: {
          outline: "none",
        },
        focus: {
          outline: "none",
        },
      };

    return styleGeograpy;
}