import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Cabecera from "./Cabecera";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useLabel } from "../../../../../shared/CustomUtilHooks";

export default function Tabla(props) {
  const { info, cabecera } = props;
  const [rows, setRows] = useState([]);
  const createLabel = useLabel();

  useEffect(() => {
    setRows(info);
  }, [info]);

  const styleCell = {
    textAlign: "center",
    fontWeight: "bold",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ "mb": 3 }}>
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            style={{ fontWeight: "bold" }}
          >
            {createLabel(cabecera)}
          </Typography>
        </Toolbar>
        <TableContainer className="scrollHorizontal">
          {
            <Table>
              <Cabecera head={cabecera} includeTool={info[0] === "No"}/>
              <TableBody>
                <TableRow>
                  {rows[0] !== "No" ? (
                    rows.map((row, i) => {
                      return (
                        <TableCell key={i} sx={styleCell} className="tablaCell">
                          {row}
                        </TableCell>
                      );
                    })
                  ) : (
                    <TableCell sx={styleCell} className="tablaCell">
                      {rows[0]}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          }
        </TableContainer>
      </Paper>
    </Box>
  );
}
