import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'https://herramientas.mexicoevalua.org/web/content';

export const getHerramientasPorEstado = async (filters) => {
	return await axios({
		method: 'post',
		url: URL + '/getHerramientasPorEstado',
		responseType: 'json',
		data: filters
	})
}

export const getBuenasPracticas = async (conceptosClave) => {
	return await axios({
		method: 'post',
		url: URL + '/getNubePalabras/buenasPracticasPorFiltros',
		responseType: 'json',
		data: conceptosClave
	})
}