import React from "react";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { infraTec } from "../../../../utils/cabeceras/infraTec";
import { capImp } from "../../../../utils/cabeceras/capImp";
import { firElec } from "../../../../utils/cabeceras/firElec";
import { sisGest } from "../../../../utils/cabeceras/sisGest";
import { expElect } from "../../../../utils/cabeceras/expElect";
import { plaInterDem } from "../../../../utils/cabeceras/plaInterDem";
import { herraVidConfe } from "../../../../utils/cabeceras/herraVidConfe";
import { juiLin } from "../../../../utils/cabeceras/juiLin";
import { herraElabPubSen } from "../../../../utils/cabeceras/herraElabPubSen";
import { plaPubSen } from "../../../../utils/cabeceras/plaPubSen";

const styleHead = {
  textAlign: "center",
  background: "#00868D",
  color: "white",
};

//eslint-disable-next-line complexity, no-magic-numbers
export default function Cabecera(props) {
  const { head, includeTool } = props;
  //eslint-disable-next-line no-magic-numbers
  const nameHead = head.split(".")[1];

  if (nameHead === "infraestructuraTecnologica")
    return llenarHeads(infraTec, includeTool);
  if (nameHead === "capacitacionImpartida")
    return llenarHeads(capImp, includeTool);
  if (nameHead === "firmaElectronica") return llenarHeads(firElec, includeTool);
  if (nameHead === "sistemaGestion") return llenarHeads(sisGest, includeTool);
  if (nameHead === "expedienteElectronico")
    return llenarHeads(expElect, includeTool);
  if (nameHead === "plataformaInterponerDemanda")
    return llenarHeads(plaInterDem, includeTool);
  if (nameHead === "herramientaVideoconferencias")
    return llenarHeads(herraVidConfe, includeTool);
  if (nameHead === "juiciosLinea") return llenarHeads(juiLin, includeTool);
  if (nameHead === "herramientasElaboracionPublicaSentencias")
    return llenarHeads(herraElabPubSen, includeTool);
  if (nameHead === "plataformaPublicacionSentencias")
    return llenarHeads(plaPubSen, includeTool);

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={styleHead}> </TableCell>
      </TableRow>
    </TableHead>
  );
}

export function llenarHeads(data, includeTool) {
  return (
    <TableHead>
      {!includeTool ? (
        data.map((fila, index) => {
          return (
            <TableRow key={index}>
              {fila.map((celda, i) => {
                return (
                  <TableCell
                    sx={
                      index === 0
                        ? styleHead
                        : { textAlign: "center", minWidth: "120px" }
                    }
                    colSpan={celda.tam}
                    key={i}
                    className="tablaCell"
                  >
                    {celda.name}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell sx={styleHead} colSpan={1} className="tablaCell">
            {data[0][0].name}
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
}
