import React from "react";
import SubtemaUno from "../TemaDos/SubtemaUno";
import SubtemaDos from "../TemaDos/SubtemaDos";
import SubtemaTres from "../TemaDos/SubtemaTres";

export default function FaseProcesoDos(props) {
  const { setBack } = props;
  return (
    <section>
      <SubtemaUno setBack={setBack}/>

      <SubtemaDos setBack={setBack}/>

      <SubtemaTres setBack={setBack}/>
    </section>
  );
}
