import React from "react";
import Proceso from "./Proceso";
import TemaProceso from "../TemaProceso";

const subtemasMapa = [
  {
    rename: "Entender necesidades",
    texto: "Entender las necesidades de las personas usuarias",
    href: "3.1",
  },
  {
    rename: "Idear nuevas soluciones",
    texto: "Idear nuevas soluciones",
    href: "3.2",
  },
  { rename: "Prototipado", texto: "Prototipado", href: "3.3" },
  {
    rename: "Poner a prueba las ideas",
    texto: "Poner a prueba las ideas",
    href: "3.3.2",
  },
  { rename: "Escalar las ideas", texto: "Escalar las ideas", href: "3.4" },
  { rename: "Implementación", texto: "Implementación", href: "3.5" },
  { rename: "Evaluación", texto: "Evaluación", href: "3.6" },
];

export default function MapaProcesos(props) {
  const { setBack } = props;
  return (
    <section>
      <div className="mapa">
        <div>
          <h3 className="titulo">
            ¿Cómo realizar un proceso de transformación tecnológica dentro de
            los poderes judiciales?
          </h3>
          <p className="indicacion">
            Selecciona algún elemento del diagrama para obtener más información
          </p>
        </div>
        <div className="MapaDiagramaInteractivo scroll">
          <Proceso
            principal={"Precondiciones necesarias"}
            subtemas={[
              {
                titulo: "Liderazgo innovador y abierto al cambio",
                href: "1.1",
              },
              {
                titulo: "Consolidación del departamento de tecnología",
                href: "1.2",
              },
            ]}
            num={1}
            next={1}
            setBack={setBack}
          />
          <Proceso
            principal={"Planificación del proceso"}
            subtemas={[
              {
                titulo: "Liderazgo para la transformación",
                href: "2.1",
              },
              {
                titulo: "Plan de desarrollo estratégico",
                href: "2.2",
              },
              {
                titulo: "Presupuesto",
                href: "2.3",
              },
            ]}
            num={2}
            next={1}
            setBack={setBack}
          />
          <Proceso
            principal={"Desarrollo de intervenciones para la transformación"}
            subtemas={[
              {
                titulo: "Diseño centrado en el usuario  ",
                href: "3.0",
              },
            ]}
            num={3}
            setBack={setBack}
          />
        </div>
        <div className="footer-mapa">
          <a href={"#/Menu-Casos-Estudio"} className="casosDeEstudioButton">
            Casos de Estudio
          </a>
          <div className="subTemaPrincipalWrapper">
            <div className="lineaDiagrama"></div>
            <div className="lineaDiagramaTemaBottom"></div>
            {subtemasMapa.map((subtema, index) => {
              return (
                // <div key={index} className="subTemaPrincipalSlide">{subtema.texto}</div>
                <TemaProceso
                  key={index}
                  texto={subtema.texto}
                  rename={subtema.rename}
                  tipo={4}
                  href={subtema.href}
                  top={true}
                  setBack={setBack}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
