import React from "react";
import Filtro from "./Filtro";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import { useLabel } from "../../../../shared/CustomUtilHooks";
import Box from '@mui/material/Box'

export default function SubListFiltros(props) {
  const { subFiltros, addFiltro, parentFilter, cleanFilters } = props;
  const createLabel = useLabel();

  return (
    <>
      {Object.keys(subFiltros).map((key) => {
        const filtros = subFiltros[key];

        if (typeof filtros === "object") {
          return (
            <List
              key={key}
              component="nav"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader" sx={{color: "#00868D", fontWeight: "bold"}}>
                  <h3 style={{fontSize: 14, paddingLeft: "1em", textAlign: 'left', lineHeight: '30px'}}>{ createLabel(key)}</h3>
                </ListSubheader>
              }
            >
              <Box role="group">
                {filtros.map((filtro, i) => {
                  return <Filtro key={i} addFiltro={addFiltro} filtro={filtro} grandParentFilter={parentFilter} parentFilter={key} cleanFilters={cleanFilters} />;
                })}
              </Box>
            </List>
          );
        } else
          return (
            <ul key={filtros} style={{ paddingLeft: "2em" }}>
              <Filtro addFiltro={addFiltro} filtro={filtros} parentFilter={parentFilter} cleanFilters={cleanFilters} />
            </ul>
          );
      })}
    </>
  );
}
