export const herraElabPubSen = [
    [
        { name: "Cuenta con herramienta para la elaboración de la versión pública de sentencias", tam: 1 },
        { name: "Año de implementación de herramienta", tam: 1 },
        { name: "Tipo de desarrollo", tam: 3 },
    ], 
    [
        { name: "", tam: 1 },
        { name: "", tam: 1 },
        { name: "Interno", tam: 1 },
        { name: "Convenio para utilizar la herramienta de otro Poder Judicial", tam: 1 },
        { name: "En colaboración con otras instituciones", tam: 1 },
    ]
];