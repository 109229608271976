
export const plaPubSen = [
    [
        { name: "Platataforma para consulta", tam: 2 },
        { name: "Año de implementación de plataforma", tam: 1 },
        { name: "Visitas a la plataforma de consulta de versiones públicas de sentencias, 2019-2021", tam: 4 },
    ],
    [
        { name: "Implementada", tam: 1 },
        { name: "En desarrollo", tam: 1 },
        { name: "", tam: 1 },
        { name: "2019", tam: 1 },
        { name: "2020", tam: 1 },
        { name: "2021 (al 30 de junio)", tam: 1 },
        { name: "Total 2019-2021", tam: 1 },
    ]
];
