import React from "react";
import Slide from "../Slide";

import icon33 from "../icons/icon3.3.png";
import icon331 from "../icons/icon3.3.1.png";
import icon332 from "../icons/icon3.3.2.png";
import icon3321 from "../icons/icon3.3.2.1.png";
import icon3322 from "../icons/icon3.3.2.2.png";
// import icon3323 from "../icons/icon3.3.2.3.png";
import icon333 from "../icons/icon3.3.3.png";

export default function SubTemaTresTres(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        prev="#/3.0/Diseño centrado en el usuario"
        subtemas={[
          { texto: "Métodos de prototipado", num: "3.3.1" },
          { texto: "Poner a prueba las ideas", num: "3.3.2" },
          { texto: "Iteración", num: "3.3.3" },
        ]}
        icon={icon33}
        subtitulo="Prototipado"
        numSubtitulo="3.3"
        cuerpo="Un prototipo es una representación aproximada de un producto, servicio o sistema (Camburn et al., 2017). Crear un prototipo proporciona una oportunidad para probar y explorar la idea, identificar errores, observar la interacción con los usuarios y aprender de manera activa (Camburn et al., 2017).
          A continuación se presentan algunas de los métodos más comunes para generar prototipos"
        casosDeEstudio={[
          "Poder Judicial de Yucatán: iteración y proceso de diseño",
          "Ministerio de Justicia de España: indicadores para evaluar intervenciones",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/3.3/Prototipado"
        icon={icon331}
        titulo="Prototipado"
        subtitulo="Métodos de prototipado"
        numSubtitulo="3.3.1"
        cuerpo="A continuación se presentan algunos de los métodos más comunes para generar prototipos:"
        cards={[
          {
            titulo: "Bocetos",
            cuerpo:
              "Los bocetos son uno de los métodos más comunes para generar un prototipo de forma rápida y fácil. Un tipo de boceto son los guiones gráficos (storyboards), que se componen de una serie de imágenes que visualizan acciones, parecidos a los que se utilizan en la industria de la animación, y que indican qué hacen los usuarios, qué dicen y cómo se mueven secuencialmente dentro de la historia (Goodwin, 2009).",
          },
          {
            titulo: "Simulación o juego de roles",
            cuerpo:
              "Esta técnica puede ayudar a visualizar una experiencia o proceso específico, así como la interacción de los usuarios con la intervención a través del mismo. Por ejemplo, se podría probar la interacción de los usuarios con quioscos electrónicos o chatbots para identificar el tipo de atención que requieren los usuarios, el tipo de mensajes o la calidad de la interacción, etc.",
          },
          {
            titulo: "Cartel conceptual",
            cuerpo:
              "El cartel conceptual resume una idea y sus partes centrales de manera concreta y visual. Por lo general, en la parte superior se incluye un boceto de la idea, a partir de lo cual se va desglosando información sobre el tipo de usuarios esperado, sus funcionalidades, forma de empleo, etc.",
          },
          {
            titulo: "Prototipos de herramientas tecnológicas",
            cuerpo:
              "Una opción común para realizar prototipos de herramientas tecnológicas es la utilización de bocetos realizados con lápiz y papel, en la actualidad también se pueden utilizar diversas herramientas para diseñar prototipos digitales, como aplicaciones, páginas web o software que permiten diseñar la interfaz de una app o programa e incluir algunas funcionalidades. Otra práctica común a la hora de crear un software es realizar prototipos codificados, es decir, por medio de algún lenguaje de programación se diseña una versión preliminar.",
          },
        ]}
        typeListCard={3}
      />
      <Slide
        setBack={setBack}
        prev="#/3.3/Prototipado"
        subtemas={[
          { texto: "Tipos de evaluación", num: "3.3.2.1" },
          { texto: "Métodos para evaluar prototipos", num: "3.3.2.2" },
          { texto: "Retos de evaluación", num: "3.3.2.3" },
        ]}
        icon={icon332}
        titulo="Prototipado"
        subtitulo="Poner a prueba las ideas"
        numSubtitulo="3.3.2"
        cuerpo="Una vez construido uno o más prototipos es esencial ponerlos a prueba con las y los usuarios para recibir su retroalimentación. Esta evaluación es fundamental para probar diversos aspectos de la intervención, así como tomar decisiones para mejorarlas."
      />
      <Slide
        setBack={setBack}
        prev="#/3.3.2/Poner a prueba las ideas"
        icon={icon3321}
        titulo="Prototipado"
        subtitulo="Tipos de evaluación"
        numSubtitulo="3.3.2.1"
        cuerpo="El tipo de prototipos que se utilizan y los aspectos que se evalúan dependen directamente de la etapa en la que se encuentren. Te presentamos tres tipos de evaluación."
        cards={[
          "a)   Pruebas de usabilidad: enfocadas en medir que tan fácil o difícil es usar la herramienta y el tiempo que las personas tardan en entender su funcionamiento, entre otras cosas.",
          "b)   Pruebas de utilidad: exploran el grado que las herramientas ayudan a las personas a lograr sus objetivos o cubrir sus necesidades.",
          "c)   Pruebas de valor: se enfocan en descubrir si las personas consideran la intervención como algo valioso o importante, si añade valor a sus vidas o incluso si pagarían para usar la herramienta.",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/3.3.2/Poner a prueba las ideas"
        icon={icon3322}
        titulo="Prototipado"
        subtitulo="Métodos para evaluar prototipos"
        numSubtitulo="3.3.2.2"
        cuerpo="Los dos métodos más comunes para evaluar prototipos iniciales son:"
        secondCuerpo={{
          titulo: "Retos de evaluación",
          cuerpo:
            "Uno de los principales retos para los poderes judiciales es la realización de evaluaciones con usuarios externos, ya que muchas veces es complicado reunir a este tipo de usuarios a dar retroalimentación. Para ello, se recomienda buscar alianzas con los colegios de abogados, escuelas de derecho, comités ciudadanos, asociaciones, organizaciones de la sociedad civil y más.",
        }}
        cards={[
          {
            titulo: "Estudio exploratorio o formativo",
            cuerpo:
              "El objetivo de este estudio es evaluar la efectividad a un nivel general y evaluar las suposiciones sobre las que se construyó el prototipo. Los usuarios tratan de llevar a cabo una serie de tareas  predefinidas o sólo dar opiniones sobre el prototipo, también es posible pedirle retroalimentación al usuario preguntándole qué y cómo puede mejorarse el prototipo, asimismo, en este tipo de pruebas se pueden evaluar dos prototipos que puedan ser similares para comparar su efectividad.",
          },
          {
            titulo: "Prueba sumativa",
            cuerpo:
              "El objetivo es probar elementos específicos de una versión más avanzada de la intervención o herramienta y detectar sus deficiencias.En esta prueba el usuario realizará una serie de tareas que pongan a prueba la funcionalidad que se quiere probar, las cuáles serán observadas por el moderador, quien no interviene en la interacción del usuario con el prototipo y sólo registra la experiencia.",
          },
        ]}
        typeListCard={3}
      />
      <Slide
        setBack={setBack}
        prev="#/3.3.2/Poner a prueba las ideas"
        // icon={icon3323}
        titulo="Prototipado"
        subtitulo="Retos de evaluación"
        numSubtitulo="3.3.2.3"
        cards={[
          "Uno de los principales retos para los poderes judiciales es la realización de evaluaciones con usuarios externos, ya que muchas veces es complicado reunir a este tipo de usuarios a dar retroalimentación. Para ello, se recomienda buscar alianzas con los colegios de abogados, escuelas de derecho, comités ciudadanos, asociaciones, organizaciones de la sociedad civil y más.",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/3.3/Prototipado"
        icon={icon333}
        titulo="Prototipado"
        subtitulo="Iteración"
        numSubtitulo="3.3.3"
        cuerpo="Una vez realizada la evaluación es necesario utilizar esa información para mejorar las herramientas que se han diseñado. Refinar una intervención requiere de un proceso de prueba y error, por ello decimos que el proceso se vuelve iterativo. 

          Para guiar el proceso de iteración las y los expertos recomiendan plantearnos las siguientes preguntas: "
        cards={[
          "¿Cuáles fueron los hallazgos durante las pruebas y evaluaciones? ",
          "¿Qué cambios son necesarios realizar en respuesta a esos hallazgos? ",
          "¿Cuál es el plan de acción para los siguientes días/ semanas?",
        ]}
        casosDeEstudio={[
          "Poder Judicial de Yucatán: iteración y proceso de diseño",
        ]}
      />
    </>
  );
}
