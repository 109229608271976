import React from "react";
import Slide from "../Slide";

import icon35 from "../icons/icon3.5.png";
import icon3511 from "../icons/icon3.5.1.1.png";
import icon3512 from "../icons/icon3.5.1.2.png";
import icon3513 from "../icons/icon3.5.1.3.png";

export default function SubTemaTresCinco(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        prev="#/3.0/Diseño centrado en el usuario"
        subtemas={[
          {
            texto: "Resistencia al cambio",
            num: "3.5.1.1",
          },
          {
            texto: "Estrategias para eliminar las resistencias",
            num: "3.5.1.2",
          },
          {
            texto: "Normatividad",
            num: "3.5.1.3",
          },
        ]}
        icon={icon35}
        titulo="Implementación"
        subtitulo="Implementación"
        numSubtitulo="3.5"
        cuerpo="Una vez que la intervención está lista para operar en condiciones reales, es momento de realizar el pilotaje. Este ejercicio es indispensable para asegurarse que la intervención funciona de la manera en la que se visualizó.

          Un reto para los poderes judiciales durante la implementación es la resistencia al cambio por parte de algunos funcionarios. A continuación se presentan algunas consideraciones
          "
        casosDeEstudio={[
          "Poder Judicial de la República Oriental del Uruguay: implementación de intervenciones",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/3.5/Implementación"
        icon={icon3511}
        titulo="Implementación"
        subtitulo="Resistencia al cambio"
        numSubtitulo="3.5.1.1"
        cuerpo="Es necesario imaginar estrategias para que la resistencia sea mínima y no obstaculice los esfuerzos realizados. La capacitación y gestión del cambio son vitales."
      />
      <Slide
        setBack={setBack}
        icon={icon3512}
        prev="#/3.5/Implementación"
        titulo="Implementación"
        subtitulo="Estrategias para eliminar las resistencias"
        numSubtitulo="3.5.1.2"
        cards={[
          "Efectuar una intervención tomando en cuenta las necesidades de los usuarios y se les ha incluido en el proceso de evaluación y retroalimentación, es muy probable que las personas la adopten sin mostrar mucha resistencia.",
          "Mejorar las intervenciones tomando en cuenta lo observado durante el pilotaje.",
          "Contar con el apoyo de funcionarios que hayan participado en todo el proceso de desarrollo  que puedan ayudar a “evangelizar” al personal de sus juzgados sobre la utilidad y funcionalidad de las nuevas intervenciones o herramientas desarrolladas.",
          "Capacitar es un elemento vital para que los usuarios, tanto internos como externos, puedan familiarizarse con la herramienta.",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/3.5/Implementación"
        icon={icon3513}
        titulo="Implementación"
        subtitulo="Normatividad"
        numSubtitulo="3.5.1.3"
        cuerpo="Es indispensable contar con un marco legal que autorice el uso de este tipo de herramientas ya que puede institucionalizar la obligatoriedad de su uso.  Al contrario, sin tener un marco jurídico que las respalde puede violar el principio de seguridad jurídica y generar importantes consecuencias jurídicas."
      />
    </>
  );
}
