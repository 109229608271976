/* eslint-disable complexity */
import { LABELS } from './labels';

const MINUMUN_ARGUMENTS = 1;

export const useLabel = () => {
	return function(code){
		if (LABELS.hasOwnProperty(code)){
			let label = LABELS[code];

			if (arguments.length <= MINUMUN_ARGUMENTS) return label
			if (!label.includes('{}')) return label

			for(let i = 1; i < arguments.length; i++){
				label = label.replace('{}',arguments[i])
			}
			
			return label
		} else return code
	}
}