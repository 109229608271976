export const sisGest = [
    [
      { name: "¿Cuenta con un sistema de gestión?", tam: 1 },
      { name: "Año de implementación", tam: 1 },
      { name: "Tipo de desarrollo", tam: 3 },
      {
        name: "Materias en las que se ha implementado un sistema de gestión por estado",
        tam: 5,
      },
      {
        name: "Interoperabilidad con instituciones externas al Poder Judicial",
        tam: 1,
      },
    ],
    [
      { name: "", tam: 1 },
      { name: "", tam: 1 },
      { name: "Interno", tam: 1 },
      { name: "Empresa privada", tam: 1 },
      { name: "En colaboración con otras instituciones", tam: 1 },
      { name: "Laboral", tam: 1 },
      { name: "Penal", tam: 1 },
      { name: "Mercantil", tam: 1 },
      { name: "Familiar", tam: 1 },
      { name: "Civil", tam: 1 },
      { name: "¿Se interconecta con instituciones externas al PJ?", tam: 1 },
    ],
  ];