export const temas = {
    Iteración: {
      color: "#5AA8AA",
      casosEstudio: ["Poder Judicial de Yucatán: iteración y proceso de diseño"],
    },
    "PJ con desarrollos tecnológicos propios": {
      color: "#4F708F",
      casosEstudio: [
        "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
        "Poder Judicial de Tamaulipas: Consolidación del departamento de tecnología",
        "Poder Judicial de la República Oriental del Uruguay: consideraciones respecto al presupuesto",
      ],
    },
    "Implementación de intervenciones": {
      color: "#4C777F",
      casosEstudio: [
        "Poder Judicial de la República Oriental del Uruguay: implementación de intervenciones",
      ],
    },
    "Alianza con diversos sectores": {
      color: "#B99DC9",
      casosEstudio: [
        "Poder Judicial del Estado de México: aseguramiento de presupuesto",
      ],
    },
    "Independencia y autonomía del departamento de tecnología": {
      color: "#E69EA5",
      casosEstudio: [
        "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
        "Poder Judicial de Tamaulipas: Consolidación del departamento de tecnología",
      ],
    },
    "Desarrollo de intervenciones": {
      color: "#04A09B",
      casosEstudio: [
        "Laboratorio de Diseño Legal de la Escuela de Derecho de la Universidad de Stanford: priorización de ideas",
      ],
    },
    "Beneficios de implementar herramientas tecnológicas": {
      color: "#2B5971",
      casosEstudio: [
        "Poder Judicial del Estado de México: aseguramiento de presupuesto",
        "Ministerio de Justicia de España: indicadores para evaluar intervenciones",
      ],
    },
    "Perfiles que integran el departamento de tecnología": {
      color: "#D6548A",
      casosEstudio: [
        "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
        "Poder Judicial de Yucatán: intervención con principios de diseño centrado en el usuario",
        "Poder Judicial de la República Oriental del Uruguay: intervención con principios de diseño centrado en el usuario",
        "Poder Judicial de la República Oriental del Uruguay: implementación de intervenciones",
      ],
    },
    "Diseño de prototipos": {
      color: "#59A8AB",
      casosEstudio: ["Poder Judicial de Yucatán: iteración y proceso de diseño"],
    },
    "Entender necesidades de usuario": {
      color: "#A1BC51",
      casosEstudio: [
        "Poder Judicial de la República Oriental del Uruguay: intervención con principios de diseño centrado en el usuario",
        "Poder Judicial de Yucatán: iteración y proceso de diseño",
      ],
    },
    "Evaluación de intervenciones": {
      color: "#153F7B",
      casosEstudio: [
        "Ministerio de Justicia de España: indicadores para evaluar intervenciones",
      ],
    },
    "Consolidación del departamento de tecnología": {
      color: "#DF653C",
      casosEstudio: [
        "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
        "Poder Judicial de Tamaulipas: Consolidación del departamento de tecnología",
      ],
    },
    Presupuesto: {
      color: "#B43638",
      casosEstudio: [
        "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
        "Poder Judicial del Estado de México: aseguramiento de presupuesto",
        "Poder Judicial de la República Oriental del Uruguay: consideraciones respecto al presupuesto",
        "Ministerio de Justicia de España: indicadores para evaluar intervenciones",
      ],
    },
    "Priorización de ideas": {
      color: "#CF8083",
      casosEstudio: [
        "Laboratorio de Diseño Legal de la Escuela de Derecho de la Universidad de Stanford: priorización de ideas",
      ],
    },
    "Diseño centrado en el usuario": {
      color: "#82C2AA",
      casosEstudio: [
        "Poder Judicial de Yucatán: intervención con principios de diseño centrado en el usuario",
        "Poder Judicial de la República Oriental del Uruguay: intervención con principios de diseño centrado en el usuario",
        "Poder Judicial de Yucatán: iteración y proceso de diseño",
      ],
    },
    "Uso de software de uso libre": {
      color: "#DC5F6A",
      casosEstudio: [
        "Poder Judicial de la República Oriental del Uruguay: consideraciones respecto al presupuesto",
      ],
    },
  };