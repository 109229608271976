import React from "react"
import CasoDeEstudio from "./CasoDeEstudio"
import {useLabel} from '../../../shared/CustomUtilHooks'
import SelectorCasosDeEstudio from "./SelectorCasosDeEstudio";

export default function MenuCasosDeEstudio(props) {
	const {back, setBack} = props;
	const createLabel = useLabel();
	return (
		<section id="Menu-Casos-Estudio">
			<SelectorCasosDeEstudio back={back} setBack={setBack} />
			<CasoDeEstudio 
				nombre="Poder Judicial del Estado de México: consolidación de departamento de tecnología"
				textoCompleto={createLabel("casoEstudio.consolidacionDepartamentoTecnologiaEstadoMexico")}
				temas={[
					{titulo: "Consolidación del departamento de tecnología", texto: [createLabel("casoEstudio.consolidacionDepartamentoTecnologiaEstadoMexico")], color: '#DF653C'},
					{titulo: "Independencia y autonomía del departamento de tecnología", texto: ["Una de las principales estrategias que se adoptaron fue la de elevar jerárquicamente, en el organigrama, al área de tecnología, con el fin de hacerla partícipe del proceso de toma de decisiones. Originalmente, el Departamento de Tecnologías dependía de la Dirección General de Administración, sin embargo, en el año 2017, se convirtió en la Dirección General de Innovación y Desarrollo Tecnológico, colocándose a la par de la Dirección General de Administración y la Dirección General de Finanzas y Planeación (I. Rodríguez, comunicación personal, 28 de mayo 2019; Circular 15/2017, 2017)9. Esto dotó al departamento de una capacidad importante en el proceso de toma de decisiones, así como de autoridad para dar seguimiento a los proyectos de transformación con las áreas involucradas. \nLo anterior trajo consigo una manera diferente de concebir al área de tecnología: pasó de ser percibida como un área de soporte y apoyo para transformarse en un área de desarrollo. Así, se incrementó su número de empleados, que pasó de 23 personas en 2015 a 100 personas en 2020."], color: "#E69EA5"},
					{titulo: "PJ con desarrollos tecnológicos propios", texto: ["Durante el proceso de fortalecimiento del área, se decidió contratar principalmente a ingenieros para desarrollo de software con el objetivo de que todos los desarrollos pertenecieran al Poder Judicial."], color: "#4F708F"},
					{titulo: "Perfiles que integran el departamento de tecnología", texto: ["Durante el proceso de fortalecimiento del área, se decidió contratar principalmente a ingenieros para desarrollo de software con el objetivo de que todos los desarrollos pertenecieran al Poder Judicial. \nPara la selección de personal se realizaron procesos de reclutamiento rigurosos, los cuales incluían la aplicación de exámenes prácticos y teóricos para asegurar la idoneidad de los perfiles."], color: "#D6548A"},
					{titulo: "Presupuesto", texto: ["se ofrecieron salarios competitivos para evitar la rotación de personal. Todo este fortalecimiento fue acompañado de cursos de actualización y un ambiente de reconocimiento a la labor de las personas que integraban el departamento. Por último, le fue asignado un presupuesto importante para su desarrollo."], color: "#B43638"}
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Poder Judicial de Tamaulipas: Consolidación del departamento de tecnología"
				textoCompleto={createLabel("casoEstudio.consolidacionDepartamentoTecnologiaTamaulipas")}
				temas={[
					{titulo: "Consolidación del departamento de tecnología", texto: [createLabel("casoEstudio.consolidacionDepartamentoTecnologiaTamaulipas")], color: "#DF653C"},
					{titulo: "PJ con desarrollos tecnológicos propios", texto: ["el software que utilizaban los juzgados para darle seguimiento a los casos se desarrollaba de manera externa. \nFue en el periodo de 2006 a 2010 que el Poder Judicial incrementó el personal de dicha área y se enfocó en contratar a desarrolladores, quienes se dieron a la tarea de replicar los sistemas de gestión que se habían desarrollado por terceros, así como implementar nuevas herramientas tecnológicas."], color: "#4F708F"},
					{titulo: "Independencia y autonomía del departamento de tecnología", texto: ["Finalmente, otra de las características que han permitido consolidar al área de tecnologías es que ésta se encuentra estructurada dentro del organigrama como una Dirección, en el mismo nivel que la Dirección de Administración y la Dirección de Finanzas."], color: "#E69EA5"}
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Poder Judicial del Estado de México: aseguramiento de presupuesto"
				textoCompleto={createLabel("casoEstudio.aseguramientoPresupuesto")}
				temas={[
					{titulo: "Presupuesto", texto: [
						"Una de las estrategias que el Poder Judicial del Estado de México llevó a cabo para asegurar la suficiencia de recursos a la hora de implementar el eje de transformación tecnológica del plan de desarrollo estratégico 2015-2020 fue la reasignación de presupuesto. Anteriormente, la visión del Poder Judicial había sido incrementar el acceso a la justicia a través de la construcción de edificios que se convirtieran en patrimonio del Poder Judicial. La nueva visión de justicia digital fue un cambio de paradigma que permitió canalizar presupuesto antes dedicado a obra, al área de tecnología (S. Medina, comunicación personal, el 23 de enero de 2020). Por otro lado, se articularon esfuerzos con el Poder Ejecutivo y Legislativo.",
						"Por otro lado, se articularon esfuerzos con el Poder Ejecutivo y Legislativo.",
						"El Poder Judicial propuso entonces al Ejecutivo la creación de un juicio electrónico para regularizar los predios y otorgar a sus dueños un título de propiedad en un tiempo menor, por lo que consiguió el financiamiento para impulsar la herramienta tecnológica.",
						"Finalmente, el Poder Judicial del Estado de México generó ahorros importantes gracias a su proceso de transformación digital. Con la implementación del expediente electrónico, que se volvió “cero papel” en ciertas materias, su consumo de papel y tóner se redujo de manera significativa."
						], color: "#B43638"},
					{titulo: "Alianza con diversos sectores", texto: ["Por otro lado, se articularon esfuerzos con el Poder Ejecutivo y Legislativo. En este sentido, se logró entrelazar la agenda del Poder Judicial con programas sociales del gobierno. Un ejemplo fue cuando el Poder Ejecutivo local manifestó entre sus prioridades la creación de un programa gubernamental de usucapión social. El Poder Judicial propuso entonces al Ejecutivo la creación de un juicio electrónico para regularizar los predios y otorgar a sus dueños un título de propiedad en un tiempo menor, por lo que consiguió el financiamiento para impulsar la herramienta tecnológica. Al respecto, Sergio Medina, entonces presidente del Poder Judicial del Estado de México, comentó la importancia de la colaboración con los poderes ejecutivos y la necesidad de visibilizar a los poderes judiciales y hacerlos atractivos para ofrecer más que “sólo dictar juicios” (S. Medina, comunicación personal, 23 de enero de 2020)."], color: "#B99DC9"},
					{titulo: "Beneficios de implementar herramientas tecnológicas", texto: ["Finalmente, el Poder Judicial del Estado de México generó ahorros importantes gracias a su proceso de transformación digital. Con la implementación del expediente electrónico, que se volvió “cero papel” en ciertas materias, su consumo de papel y tóner se redujo de manera significativa."], color: "#2B5971"},
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Poder Judicial de la República Oriental del Uruguay: consideraciones respecto al presupuesto"
				textoCompleto={createLabel("casoEstudio.consideracionesRespectoAlPresupuesto")}
				temas={[
					{titulo: "Presupuesto", texto: [
						"En 2001, Uruguay experimentaba una fuerte crisis económica y el sector público enfrentó diversos cortes presupuestales que dejaron al Poder Judicial con una capacidad limitada para contratar y pagar a proveedores. Frente a las reducciones presupuestales, el Poder Judicial decidió apostar por contratar a más personal en el área de tecnología que pudiera desarrollar sistemas internos (M. Pesce, comunicación personal, 24 de agosto de 2021).",
						"Además, se utiliza exclusivamente software de uso libre, lo que ha reducido de forma considerable el costo derivado del pago de licencias. A la fecha, el Poder Judicial de Uruguay ha logrado mantener un costo de funcionamiento informático de aproximadamente 5,200,000.00 pesos anuales, el equivalente a unos 260,000.00 dólares (esto incluye el presupuesto para desarrollar herramientas tecnologías y pago de licencias, excluyendo los sueldos de su personal y costos de funcionamiento como luz e Internet). Además, se estima que el costo total informático de procesamiento de un caso es de aproximadamente cuatro dólares (M. Pesce, comunicación personal, 24 de agosto de 2021)"
						], color: "#B43638"},
					{titulo: "PJ con desarrollos tecnológicos propios", texto: [
						"decidió apostar por contratar a más personal en el área de tecnología que pudiera desarrollar sistemas internos (M. Pesce, comunicación personal, 24 de agosto de 2021).",
						"Actualmente la política de desarrollo interno sigue vigente dado que la gran mayoría de las herramientas son realizadas por el departamento de tecnología, lo que permite, entre otras cosas, que todas interoperen entre sí. Por ejemplo, se desarrolló un sistema único de gestión judicial para todas las materias y todas las instancias, que permite intercambiar información con diversos sistemas dentro y fuera del Poder Judicial.",
						"El director general de los Servicios Administrativos, Marcelo Pesce, enfatizó que la estrategia de desarrollar todos los programas y herramientas de manera interna responde a la necesidad de reconocer que los servicios que proveen los poderes judiciales son continuos e infinitos, ya que la impartición de justicia no se agota en una fecha o periodo. Por ello, es necesario tener una perspectiva de largo plazo que permita una mejora constante de los servicios y donde el desarrollo interno de herramientas responda justamente a esa lógica de funcionamiento, en comparación con la adquisición de software de terceros que no facilita implementar las mejoras."
						], color: "#4F708F"},
					{titulo: "Uso de software de uso libre", texto: ["Además, se utiliza exclusivamente software de uso libre, lo que ha reducido de forma considerable el costo derivado del pago de licencias. A la fecha, el Poder Judicial de Uruguay ha logrado mantener un costo de funcionamiento informático de aproximadamente 5,200,000.00 pesos anuales, el equivalente a unos 260,000.00 dólares (esto incluye el presupuesto para desarrollar herramientas tecnologías y pago de licencias, excluyendo los sueldos de su personal y costos de funcionamiento como luz e Internet). Además, se estima que el costo total informático de procesamiento de un caso es de aproximadamente cuatro dólares (M. Pesce, comunicación personal, 24 de agosto de 2021)"], color: "#DC5F6A"},
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Poder Judicial de Yucatán: intervención con principios de diseño centrado en el usuario"
				textoCompleto={createLabel("casoEstudio.intervencionPrincipiosDiseñoYucatan")}
				temas={[
					{titulo: "Perfiles que integran el departamento de tecnología", texto: ["Desde el inicio del proceso, se conformó un grupo multidisciplinario encargado de desarrollar el proyecto; éste incluye a diversos tipos de usuarios que aportan el conocimiento técnico. Lo explica así la directora del Departamento de Innovación e Implementación de Sistemas, Verónica Castillo Loría: La metodología genera un equipo de trabajo interdisciplinario y ese equipo de trabajo ya no replica la situación de informáticos con el usuario o informáticos con el abogado, sino que somos un equipo de trabajo compuesto de diferentes personas dependiendo del sistema que estemos desarrollando, porque lo hacemos tanto para sistemas administrativos como para sistemas jurídicos. Dentro de ese equipo de trabajo, pues nos acompañan expertos en la operación (V. Castillo Loría, comunicación personal, el 21 de agosto de 2021)."], color: "#D6548A"},
					{titulo: "Diseño centrado en el usuario", texto: [
						"Desde hace varios años, el Poder Judicial de Yucatán ha implementado la metodología de trabajo SCRUM para el desarrollo de proyectos. Aunque esta metodología tiene como objetivo la gestión de proyectos complejos, incorpora principios del diseño centrado en el usuario durante todo el proceso. Además, el Poder Judicial ha adaptado esta metodología a sus necesidades, incorporando una etapa de evaluación de usabilidad durante la cual se realizan pruebas con los usuarios para recibir su retroalimentación. ",
						"Una de las principales etapas de este proceso consiste en definir la herramienta, a partir de las necesidades de las y los usuarios y de una lista de requisitos y funcionalidades que el producto debe incluir para considerarse finalizado.",
						"Así lo expresa Castillo Loría: Una vez que me definen qué proyectos se van a trabajar, normalmente esto lo hacemos en septiembre-octubre, por ejemplo de 2021, para planear lo que vamos a hacer en 2022, escogemos a los dueños del proceso. Y dentro de la primera actividad que se lleva en esta metodología es la definición del product backlog. El product backlog no es nada más que un contenedor de deseos de requerimientos; entonces este contenedor está organizado por un conjunto de historias de usuario. Las historias de usuario tienen tres partes importantes. Uno: ¿en qué calidad lo quiero? Por ejemplo, como secretario de acuerdos. Ya definí el rol, deseo un reporte de inicios. Esa es la segunda parte: ¿qué es lo que quieres? ¿Qué quieres que haga la funcionalidad? Y, por último, ¿para qué? (V. Castillo Loría, comunicación personal, el 21 de agosto de 2021).",
						"De acuerdo con la entrevistada, los dueños del proceso son generalmente funcionarios  jurisdiccionales o administrativos que forman parte del equipo de desarrollo para participar en proyectos específicos y que expresan diversas necesidades a través de las historias de usuario. Para recopilar esta información se conforma un tablero de requerimientos con todas las historias de usuario, generalmente en pequeños bloques de papel adhesivos, que son los que van guiando todo el proceso"
						], color: "#82C2AA"},
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Poder Judicial de la República Oriental del Uruguay: intervención con principios de diseño centrado en el usuario"
				textoCompleto={createLabel("casoEstudio.intervencionPrincipiosDiseñoUruguay")}
				temas={[
					{titulo: "Perfiles que integran el departamento de tecnología", texto: ["Para el Poder Judicial de Uruguay, uno de los principales pasos antes de iniciar cualquier proyecto es establecer un equipo de trabajo. Este equipo está integrado generalmente por funcionarios administrativos y jurisdiccionales, además de abogados, entre otros usuarios."], color: "#D6548A"},
					{titulo: "Entender necesidades de usuario", texto: ["Al respecto, la directora del Área Jurisdiccional de la División de Tecnología, Fabiana Cosentino, dijo: Nos reunimos y hacemos un análisis exhaustivo de cómo va a ser. Antes de pensar con quién lo vamos a programar y cómo, ni quién lo va a hacer, hacemos un buen diseño y preguntamos, por ejemplo, al Colegio de Abogados, a organismos, las necesidades. Recabamos la información de lo que se está precisando más allá de lo que nosotros intuimos que puede ser útil (F. Cosentino, comunicación personal, 24 de agosto de 2021). Una vez que se ha recopilado información acerca de las y los usuarios, se realiza un documento de especificaciones para el desarrollo del proyecto."], color: "#A1BC51"},
					{titulo: "Diseño centrado en el usuario", texto: [
						"Para el Poder Judicial de Uruguay, uno de los principales pasos antes de iniciar cualquier proyecto es establecer un equipo de trabajo. Este equipo está integrado generalmente por funcionarios administrativos y jurisdiccionales, además de abogados, entre otros usuarios.", 
						"Al respecto, la directora del Área Jurisdiccional de la División de Tecnología, Fabiana Cosentino, dijo: Nos reunimos y hacemos un análisis exhaustivo de cómo va a ser. Antes de pensar con quién lo vamos a programar y cómo, ni quién lo va a hacer, hacemos un buen diseño y preguntamos, por ejemplo, al Colegio de Abogados, a organismos, las necesidades. Recabamos la información de lo que se está precisando más allá de lo que nosotros intuimos que puede ser útil (F. Cosentino, comunicación personal, 24 de agosto de 2021). Una vez que se ha recopilado información acerca de las y los usuarios, se realiza un documento de especificaciones para el desarrollo del proyecto."
						], color: "#82C2AA"},
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Laboratorio de Diseño Legal de la Escuela de Derecho de la Universidad de Stanford: priorización de ideas"
				textoCompleto={createLabel("casoEstudio.priorizacionIdeas")}
				temas={[
					{titulo: "Uso de software de uso libre", texto: [
						"El Laboratorio de Diseño Legal de la Escuela de Derecho de la Universidad de Stanford ha llevado a cabo diversos ejercicios de codiseño con actores clave para poder identificar las ideas más prometedoras durante sus intervenciones.",
						"Una de las actividades realizadas consiste en presentar a los participantes cada una de las ideas generadas por el equipo de diseño escritas en un pedazo de papel, así como tarjetas blancas en dado caso de que los participantes quieran añadir nuevas ideas. Posteriormente, se les pide que imaginen que han sido contratados por una fundación filantrópica para asignar recursos a cada una de esas ideas.",
						"El objetivo es que los participantes clasifiquen esas ideas de acuerdo con los recursos que les otorgarían en una tabla con cuatro categorías: alto valor (100,000 dólares), valor medio (50,000 dólares), valor bajo (10,000 dólares) y sin valor (0 dólares). Por último, las y los participantes explican las razones detrás de cada una de sus decisiones (Hagan, 2019)."
					], color: "#DC5F6A"},
					{titulo: "Priorización de ideas", texto: [
						"El Laboratorio de Diseño Legal de la Escuela de Derecho de la Universidad de Stanford ha llevado a cabo diversos ejercicios de codiseño con actores clave para poder identificar las ideas más prometedoras durante sus intervenciones.",
						"Una de las actividades realizadas consiste en presentar a los participantes cada una de las ideas generadas por el equipo de diseño escritas en un pedazo de papel, así como tarjetas blancas en dado caso de que los participantes quieran añadir nuevas ideas. Posteriormente, se les pide que imaginen que han sido contratados por una fundación filantrópica para asignar recursos a cada una de esas ideas.",
						"El objetivo es que los participantes clasifiquen esas ideas de acuerdo con los recursos que les otorgarían en una tabla con cuatro categorías: alto valor (100,000 dólares), valor medio (50,000 dólares), valor bajo (10,000 dólares) y sin valor (0 dólares). Por último, las y los participantes explican las razones detrás de cada una de sus decisiones (Hagan, 2019)."
					], color: "#CF8083"},
					{titulo: "Desarrollo de intervenciones", texto: [
						"El Laboratorio de Diseño Legal de la Escuela de Derecho de la Universidad de Stanford ha llevado a cabo diversos ejercicios de codiseño con actores clave para poder identificar las ideas más prometedoras durante sus intervenciones.",
						"Una de las actividades realizadas consiste en presentar a los participantes cada una de las ideas generadas por el equipo de diseño escritas en un pedazo de papel, así como tarjetas blancas en dado caso de que los participantes quieran añadir nuevas ideas. Posteriormente, se les pide que imaginen que han sido contratados por una fundación filantrópica para asignar recursos a cada una de esas ideas.",
						"El objetivo es que los participantes clasifiquen esas ideas de acuerdo con los recursos que les otorgarían en una tabla con cuatro categorías: alto valor (100,000 dólares), valor medio (50,000 dólares), valor bajo (10,000 dólares) y sin valor (0 dólares). Por último, las y los participantes explican las razones detrás de cada una de sus decisiones (Hagan, 2019)."
					], color: "#04A09B"},
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Poder Judicial de Yucatán: iteración y proceso de diseño"
				textoCompleto={createLabel("casoEstudio.iteracionProcesoDiseño")}
				temas={[
					{titulo: "Iteración", texto: [createLabel("casoEstudio.iteracionProcesoDiseño")], color: "#5AA8AA"},
					{titulo: "Diseño de prototipos", texto: [
						"Con el objetivo de evaluar su usabilidad y funcionalidad, el Poder Judicial de Yucatán ha incorporado la elaboración de prototipos tanto en las fases iniciales como en las fases finales de desarrollo de software.",
						"Una vez que se han definido las necesidades de los usuarios y se tienen ideas acerca de cómo resolverlas, se elabora un prototipo de baja fidelidad para una prueba inicial. Presentamos un prototipo que puede ser en papel, que puede ser con pantallas o algún software, que sirva para simular lo que va a ser (…) Realmente ayudan mucho las pruebas de usabilidad y no entorpecen los principios básicos de la metodología. Hoy por hoy, si podemos, aplicamos en dos puntos las pruebas de usabilidad (V. Castillo Loría, comunicación personal, el 21 de agosto de 2021).",
						"La complejidad del prototipo aumenta conforme se avanza en el proyecto, hasta la realización de una prueba de usabilidad con los usuarios finales para evaluar la funcionalidad de la herramienta, recibir su retroalimentación e identificar potenciales mejoras.",
						"Al final del desarrollo, presentamos [el prototipo], o sea, ya dejamos que el usuario [la use], sin capacitarles, sin decirle nada. Se invita a varias personas que participan en el uso de ese sistema y se ponen a probar, a revisar. Esa prueba de usabilidad consiste inclusive en grabar a la persona. El programador está viendo a la persona porque vemos estas facciones, por ejemplo, cuando de repente necesita agarrar el mouse en lugar de soltar el teclado, entonces son esas situaciones que nos ayudan a nosotros a identificar que el desarrollo que estamos haciendo es funcional, es fácil, o qué podemos hacer para mejorar la interfaz inclusive del sistema (V. Castillo Loría, comunicación personal, el 21 de agosto de 2021)."
						], color: "#59A8AB"},
					{titulo: "Diseño centrado en el usuario", texto: [
						"Una vez que se han definido las necesidades de los usuarios y se tienen ideas acerca de cómo resolverlas, se elabora un prototipo de baja fidelidad para una prueba inicial. Presentamos un prototipo que puede ser en papel, que puede ser con pantallas o algún software, que sirva para simular lo que va a ser (…) Realmente ayudan mucho las pruebas de usabilidad y no entorpecen los principios básicos de la metodología. Hoy por hoy, si podemos, aplicamos en dos puntos las pruebas de usabilidad (V. Castillo Loría, comunicación personal, el 21 de agosto de 2021).",
						"La complejidad del prototipo aumenta conforme se avanza en el proyecto, hasta la realización de una prueba de usabilidad con los usuarios finales para evaluar la funcionalidad de la herramienta, recibir su retroalimentación e identificar potenciales mejoras.",
						"Al final del desarrollo, presentamos [el prototipo], o sea, ya dejamos que el usuario [la use], sin capacitarles, sin decirle nada. Se invita a varias personas que participan en el uso de ese sistema y se ponen a probar, a revisar. Esa prueba de usabilidad consiste inclusive en grabar a la persona. El programador está viendo a la persona porque vemos estas facciones, por ejemplo, cuando de repente necesita agarrar el mouse en lugar de soltar el teclado, entonces son esas situaciones que nos ayudan a nosotros a identificar que el desarrollo que estamos haciendo es funcional, es fácil, o qué podemos hacer para mejorar la interfaz inclusive del sistema (V. Castillo Loría, comunicación personal, el 21 de agosto de 2021).",
						"Otro aspecto interesante del proceso de diseño de herramientas tecnológicas del Poder Judicial de Yucatán es que el desarrollo de software se divide en varios ciclos, los cuales tienen una duración de cuatro semanas. En cada uno de ellos, por medio de las ya mencionadas “historias de usuario”, se establecen los requerimientos por parte de los usuarios que se deben desarrollar. Además, durante estas cuatro semanas, se realizan reuniones diarias con los usuarios de 15 minutos para hablar de los avances del proyecto. Esta estrategia es interesante porque dividir el proceso en varios ciclos permite al equipo de desarrollo probar la herramienta o intervención desarrollada en distintos puntos para ir recibiendo de manera progresiva la retroalimentación de las personas usuarias, evitando dejar esta retroalimentación para el final del proyecto cuando todo el desarrollo ya está hecho y donde es posible que un error no identificado en etapas tempranas haya escalado a la versión fina"
						], color: "#82C2AA"},
					{titulo: "Entender necesidades de usuario", texto: ["Otro aspecto interesante del proceso de diseño de herramientas tecnológicas del Poder Judicial de Yucatán es que el desarrollo de software se divide en varios ciclos, los cuales tienen una duración de cuatro semanas. En cada uno de ellos, por medio de las ya mencionadas “historias de usuario”, se establecen los requerimientos por parte de los usuarios que se deben desarrollar. Además, durante estas cuatro semanas, se realizan reuniones diarias con los usuarios de 15 minutos para hablar de los avances del proyecto. Esta estrategia es interesante porque dividir el proceso en varios ciclos permite al equipo de desarrollo probar la herramienta o intervención desarrollada en distintos puntos para ir recibiendo de manera progresiva la retroalimentación de las personas usuarias, evitando dejar esta retroalimentación para el final del proyecto cuando todo el desarrollo ya está hecho y donde es posible que un error no identificado en etapas tempranas haya escalado a la versión fina"], color: "#A1BC51"},
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Poder Judicial de la República Oriental del Uruguay: implementación de intervenciones"
				textoCompleto={createLabel("casoEstudio.implementacionIntervenciones")}
				temas={[
					{titulo: "Implementación de intervenciones", texto: [createLabel("casoEstudio.implementacionIntervenciones")], color: "#4C777F"},
					{titulo: "Perfiles que integran el departamento de tecnología", texto: [
						"El equipo del área de tecnologías del Poder Judicial de Uruguay comprende no solamente a especialistas que cuentan con experiencia en desarrollos informáticos y tecnológicos, sino también a personas con experiencia en el ámbito operativo jurisdiccional (como escribientes, actuarios y abogados). Este equipo multidisciplinario se encarga de probar los desarrollos del área y apoyar en su implementación y en la capacitación dirigida a los usuarios internos para que aprendan a utilizar las nuevas herramientas y todas sus funcionalidades.",
						"Al respecto, cuando se trata de implementar nuevas herramientas tecnológicas, parte de este equipo se traslada a los juzgados donde se efectuará la implementación. Durante este proceso, las personas con experiencia jurisdiccional, que además conocen el sistema que se está implementando, son las encargadas de la capacitación. Este modelo de capacitación de pares se ha desarrollado debido a que muchas veces surgen dudas funcionales que requieren conocimientos técnico-jurídicos, que no necesariamente tienen los programadores. Tenemos equipos de capacitadoras. Ellas van de a dos, son dos actuarias, escribanas o abogadas que trabajan en tecnología con nosotros y que saben usar el sistema"
					], color: "#D6548A"},
				]}
			/>
			<CasoDeEstudio 
			back= {back}
				nombre="Ministerio de Justicia de España: indicadores para evaluar intervenciones"
				textoCompleto={createLabel("casoEstudio.indicadoresEvaluarIntervenciones")}
				temas={[
					{titulo: "Evaluación de intervenciones", texto: [
						"El Ministerio de Justicia de España, a través de la Dirección General de Transformación Digital de la Administración de Justicia, ha realizado diversas transformaciones tecnológicas al interior de los poderes judiciales en ese país, durante la última década. Para medir el impacto de dichas intervenciones, se han establecido cinco objetivos a alcanzar, cada uno con distintos indicadores, que reflejan el tipo de justicia que se quiere alcanzar.",
						"1) Justicia más eficiente. Este objetivo está enfocado en el mejoramiento de los resultados y se evalúa en términos de los recursos ahorrados en desplazamientos a la sede judicial, las horas de trabajo ahorradas gracias al uso de herramientas tecnológicas y la estimación de la fuerza de trabajo adicional disponible que representa la automatización.",
						"2) Justicia más conciliadora. Se enfoca en mejorar la vida familiar y profesional de los funcionarios. Se mide en términos del número de personas que teletrabajan y su consecuencia en el fomento en la economía de los lugares de origen, ya que los funcionarios no se tienen que desplazar.",
						"3) Justicia más verde. Este objetivo está orientado a reducir el impacto del cambio climático y se mide por la cantidad de CO2 que se evita emitir gracias a que los funcionarios y los usuarios no se desplazan a las sedes judiciales.",
						"4) Justicia más transparente. Se enfoca en hacer accesible y pública la justicia. Se mide con el número promedio de personas que ven la retransmisión de las audiencias judiciales realizadas por medios electrónicos.",
						"5) Justicia más segura. Permite abordar los retos de ciberseguridad que se plantean. Se evalúa por medio de la capacitación dirigida a los funcionarios en termas de ciberseguridad (número de horas ofrecidas, número de usuarios capacitados y número de acciones de divulgación) y los incidentes de seguridad gestionados. "
						], color: "#153F7B"},
					{titulo: "Beneficios de implementar herramientas tecnológicas", texto: ["Una de las ventajas de contar con estos indicadores es que son útiles para demostrar el uso eficiente del presupuesto que se asigna al departamento de tecnología y proporcionar evidencia de cómo los recursos invertidos en dicha área se multiplican en términos de resultados.  Al respecto, el subdirector general de Planificación y Gestión de la Transformación Digital menciona: Uno de los aspectos importantes es contar los beneficios, contar los beneficios que estamos consiguiendo a través de la transformación digital, lo que queremos ver es que el apartado de transformación digital no es un centro de gastos, sino que es un centro que lo que permite es multiplicar cada euro que se invierte en tecnología, por cinco, por seis, por diez o por veinte, de forma que se puede, ya no solamente mejorar el servicio público que percibe el ciudadano, sino tener un rendimiento económico que se puede cuantificar, que se puede evaluar (J. Barba Lobatón, comunicación personal, el 13 de septiembre de 2021)."], color: "#2B5971"},
					{titulo: "Presupuesto", texto: ["Una de las ventajas de contar con estos indicadores es que son útiles para demostrar el uso eficiente del presupuesto que se asigna al departamento de tecnología y proporcionar evidencia de cómo los recursos invertidos en dicha área se multiplican en términos de resultados.  Al respecto, el subdirector general de Planificación y Gestión de la Transformación Digital menciona: Uno de los aspectos importantes es contar los beneficios, contar los beneficios que estamos consiguiendo a través de la transformación digital, lo que queremos ver es que el apartado de transformación digital no es un centro de gastos, sino que es un centro que lo que permite es multiplicar cada euro que se invierte en tecnología, por cinco, por seis, por diez o por veinte, de forma que se puede, ya no solamente mejorar el servicio público que percibe el ciudadano, sino tener un rendimiento económico que se puede cuantificar, que se puede evaluar (J. Barba Lobatón, comunicación personal, el 13 de septiembre de 2021)."], color: "#B43638"},
				]}
			/>
		</section>
	)
}