import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useLabel } from "../../../../shared/CustomUtilHooks";

export default function Filtro(props) {
  const { addFiltro, filtro, grandParentFilter, parentFilter, cleanFilters } = props;
  const [activado, setActivado] = useState(false);
  const createLabel = useLabel();

  React.useEffect(() => {
    if (cleanFilters) setActivado(false);
  }, [cleanFilters])

  return (
    <ListItemButton sx={{ "pl": 4 }}
      dense
      onClick={() => {
        setActivado(!activado);
        addFiltro(grandParentFilter, parentFilter, filtro, !activado);
      }}
    >
      <ListItemIcon>
        <Checkbox
          checked={activado}
          onChange={() => {
            setActivado(!activado);
          }}
        />
      </ListItemIcon>
      <ListItemText primary={ createLabel(filtro)} />
    </ListItemButton>
  );
}
