export const herraVidConfe = [
  [
    {
      name: "Realiza audiencias a distancia por medio de videoconferencias",
      tam: 1,
    },
    {
      name: "¿Cuenta con protocolos para realizar audiencias por videoconferencia?",
      tam: 1,
    },
    {
      name: "¿De qué manera se verifica la identidad de las personas que participan en las audiencias que se llevan a cabo vía remota?",
      tam: 4,
    },
    { name: "Año de implementación", tam: 1 },
    { name: "Número de audiencias realizadas por videoconferencias", tam: 5 },
    {
      name: "Materias en las que se ha implementado el uso de videoconferencia",
      tam: 5,
    },
  ],
  [
    { name: "", tam: 1 },
    { name: "", tam: 1 },
    { name: "Mostrando identificación frente a la cámara", tam: 1 },
    {
      name: "Se verifica previo a la audiencia por una persona funcionaria en sala, a través de un registro o vía oficio",
      tam: 1,
    },
    { name: "Por medio de firma electrónica", tam: 1 },
    { name: "No sabe/ no responde", tam: 1 },
    { name: "", tam: 1 },
    { name: "2018", tam: 1 },
    { name: "2019", tam: 1 },
    { name: "2020", tam: 1 },
    { name: "2021 (al 30 de junio)", tam: 1 },
    { name: "Total 2018-2021", tam: 1 },
    { name: "Laboral", tam: 1 },
    { name: "Penal", tam: 1 },
    { name: "Mercantil", tam: 1 },
    { name: "Familiar", tam: 1 },
    { name: "Civil", tam: 1 },
  ],
];
