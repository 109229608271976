import React, { useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import MenuFiltrosNube from "./MenuFiltrosNube";
import {
  BtnBuscar,
  BtnDownload,
  BtnCleanSearch,
} from "../mapaDeMexico/tabulador/Buttons";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { getBuenasPracticas } from "../utils/api";
import excelCloudWords from "../utils/excelCloudWords";


const styles = {
  filtros: {
    backgroundColor: "#FFF",
    display: "flex",
    paddingTop: "24px",
    flexDirection: "column",
    height: "100%",
  },
  filtrosWrapper: {
    height: "50%",
    borderBottom: "2px solid #EEE",
  },
};

export default function FiltrosNube(props) {
  const { setOpenDrawer, setBuenasPracticas, buenasPracticas } = props;
  const [filters, setFilters] = React.useState([]);

  const handleSearch = () => {
    getBuenasPracticas({ conceptosClave: filters }).then((results) =>
      setBuenasPracticas(results.data.data.results)
    );
  };

  useEffect(()=> {}, [filters])

  return (
    <Grid container sx={styles.filtros} rowGap={"24px"} direction="column">
      <Grid
        item
        style={{ display: "flex", justifyContent: "end", padding: "0 20px" }}
      >
        <IconButton
          onClick={() => setOpenDrawer(false)}
          style={{ borderRadius: "2px", border: "1px solid #EEE" }}
        >
          <KeyboardDoubleArrowLeftIcon />
        </IconButton>
      </Grid>
      <Grid item display={"flex"} justifyContent={"center"}>
        <h2>Filtros</h2>
      </Grid>
      <Grid item sx={styles.filtrosWrapper} className="scroll">
        <MenuFiltrosNube setFilters={setFilters} filters={filters} />
      </Grid>
      <Grid
        item
        sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}
      >
        <BtnBuscar
          texto={"Buscar"}
          onClick={() => {
            handleSearch();
            setOpenDrawer(false);
          }}
        />
        <BtnDownload
          texto={"Descargar"}
          onClick={() => excelCloudWords(buenasPracticas)}
        />
        <BtnCleanSearch
          texto={"Borrar búsqueda"}
          onClick={() => {
            setFilters([]);
			setBuenasPracticas();
          }}
        />
      </Grid>
    </Grid>
  );
}
