import React, { useEffect, useState } from "react";
import { ComposableMap, Geographies } from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import EntidadFederativa from "./EntidadFederativa";

const XCOORDINATE = -4.5;
const YCOORDINATE = 25;

function Map(props) {
  const [tooltip, setTooltip] = useState("");
  const [entidadesAct, setEntidadesAct] = useState([]);
  const { optionState, clickEntidad, herramienta, ficha, comparadores, entidadFed, setEntidadFed } = props;
  const geoUrl = "https://code.highcharts.com/mapdata/countries/mx/mx-all.topo.json";

  useEffect(() => {
    const aux = [];
    comparadores.map((comp) => {
      aux.push(comp.idPoder);
    });
    setEntidadesAct(aux);
  }, [comparadores]);

  return (
    <>
      <ReactTooltip data-effect="solid">{tooltip}</ReactTooltip>
      <ComposableMap
        width={550} height={400}
        projection="geoAlbers"
        projectionConfig={{
          center: [XCOORDINATE, YCOORDINATE],
        }}
        data-tip=""
        style={{ outline: "none" }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              return (
                <EntidadFederativa
                  key={geo.rsmKey}
                  geo={geo}
                  setTooltip={setTooltip}
                  optionState={optionState}
                  clickEntidad={clickEntidad}
                  herramienta={herramienta}
                  ficha={ficha}
                  entidadesAct={entidadesAct}
                  entidadFed={entidadFed}
                  setEntidadFed={setEntidadFed}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </>
  );
}

export default Map;
