import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    warning: {
      main: "#FFCF06",
      contrastText: "#000",
    },
    error: {
      main: "#CF0A0A",
      contrastText: "#000",
    },
    info: {
      main: "#31CFB2",
      contrastText: "#000",
    },
    "info-2": {
      main: "#00868D",
      contrastText: "#fff",
    },
    "info-3": {
      main: "#263357",
      contrastText: "#fff",
    },
    "success":{
      main: "#50B747",
      contrastText: "#fff",
    },
    "red-signal": {
      main: "#8D0008",
      contrastText: "#fff",
    }
  },
});
