import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import InfoPoder from "./InfoPoder";


export default function PoderesActivos(props) {
  const { name, logoUrl, index, handleChange, expanded, dataPoder } = props;
  const modulo = 2;
  const fondoPoder = () => {
    if (index % modulo === 0) {
      return "#EEEEEE";
    } else {
      return "#94D4C4";
    }
  };

  return (
    <Accordion
      expanded={expanded === `panel-${index}`}
      onChange={handleChange(`panel-${index}`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id={`panel${index}a-header`}
        sx={{ background: fondoPoder }}
      >
        <div className="logo">
          <img src={logoUrl} alt={`logo-${name}`} />
        </div>
        <Typography sx={{ fontWeight: "Bold" }}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ border: "none" }}>
        <InfoPoder dataPoder={dataPoder} />
      </AccordionDetails>
    </Accordion>
  );
}
