/* eslint-disable complexity */
/* eslint-disable id-length */
/* eslint-disable no-magic-numbers */

import React from "react";
import ReactWordcloud from "react-wordcloud";
import { select } from "d3-selection";
import { words } from "./Words";
import {
  Button,
  Card,
  Drawer,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ComparatorContent } from "../mapaDeMexico/tabulador/ComparadorTecDig";
import FiltrosNube from "./FiltrosNube";
import { FilterAlt, Help } from "@mui/icons-material";
import { theme } from "../utils/themeMUI";
import { getBuenasPracticas } from "../utils/api";
import { useLabel } from "../../shared/CustomUtilHooks";

import ResumeGoodPractices from "./ResumeGoodPractices";

const drawerWidth = 350;
const ONCE = 1;

const styles = {
  mainContainer: {
    width: "100vw",
    minHeight: 700,
    height: "95vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3em"
  },
  wordWrapper: {
    width: "100%",
    height: "100%",
  },
  card: {
    display: "flex",
    flexShrink: 3,
    width: 275,
    height: 150,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00868D",
    textAlign: "center",
    color: "white",
    "&:hover": { transform: "scale(1.1)" },
  },
};

export default function WordCloudWrapper() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [buenasPracticas, setBuenasPracticas] = React.useState();
  const createLabel = useLabel();
  const options = {
    deterministic: true,
    enableOptimizations: true,
    rotationAngles: [0],
    rotations: 1,
    enableTooltip: false,
    fontFamily: "impact",
    fontWeight: "bold",
    padding: 10,
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1500,
    colors: ["#000000", "#263357", "#2b5971", "#00a399", "#08b1a9", "#31cfb2", "#f46d11", "#ffcf06"]
  };

  React.useEffect(() => {
    document.title = "Nube de Palabras";
  }, []);

  const getCallback = (callback) => {
    // eslint-disable-next-line complexity
    return function (word, event) {
      const isActive = callback !== "onWordMouseOut";
      const element = event.target;
      const text = select(element);
      text
        .on("click", () => {
          if (isActive) {
            getBuenasPracticas({ conceptosClave: [word.text] }).then((result) =>
              setBuenasPracticas(result.data.data.results)
            );
            setOpenDrawer(false)
          }
        })
        .attr("background", "white")
        .attr("text-decoration", isActive ? "underline" : "none");
    };
  };

  const callbacks = {
    onWordClick: getCallback("onWordClick") ,
    onWordMouseOver: getCallback("onWordMouseOver"),
  };

  return (
    <Box style={styles.mainContainer} className="wordCloudMainContainer">
      <Card style={styles.wordWrapper} sx={{ display: "flex", height: "85%" }}>
        <div style={{ position: "absolute" }}>
          <ThemeProvider theme={theme}>
            <Button
              color="info-2"
              variant="contained"
              aria-label="open drawer"
              startIcon={<FilterAlt />}
              onClick={() => setOpenDrawer(true)}
              sx={{
                ...(openDrawer && { display: "none" }),
                margin: "30px",
              }}
            >
              Filtros
            </Button>
          </ThemeProvider>
        </div>
        <Drawer
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              position: "static",
            },
          }}
          anchor="left"
          open={openDrawer}
          variant="persistent"
        >
          <FiltrosNube
            setOpenDrawer={setOpenDrawer}
            setBuenasPracticas={setBuenasPracticas}
            buenasPracticas={buenasPracticas}
          />
        </Drawer>
        <ComparatorContent open={openDrawer}>
          <Grid container direction={"column"} height={"100%"}>
            <Grid item height={"70%"} marginBottom="24px">
              <ReactWordcloud
                callbacks={callbacks}
                words={words}
                options={options}
              />
            </Grid>
            <Grid
              container
              item
              direction={"row"}
              spacing={2}
              dislay={"flex"}
              justifyContent={"center"}
              height="25%"
              flexWrap={"nowrap"}
              columnGap="10px"
            >
              {buenasPracticas ? (
                buenasPracticas.length > 0 ? (
                  <ResumeGoodPractices buenasPracticas={buenasPracticas} />
                ) : (
                  <Typography>
                    {createLabel(
                      "comparador.resultsFound",
                      buenasPracticas.length,
                      buenasPracticas.length === ONCE
                        ? "resultado"
                        : "resultados"
                    )}
                  </Typography>
                )
              ) : (
                <div className="mensajeComparador">
                  <Help color="info" fontSize="large" />
                  <h3> {createLabel("nubePalabras.noDataSelected")} </h3>
                </div>
              )}
            </Grid>
          </Grid>
        </ComparatorContent>
      </Card>
    </Box>
  );
}
