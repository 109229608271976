/* eslint-disable max-lines */
export const words = [
  {text:"Costa Rica", value:2},
  {text:"Canadá", value:3},
  {text:"Chile", value:6},
  {text:"México", value:16},
  {text:"Argentina", value:3},
  {text:"Australia", value:2},
  {text:"Colombia", value:2},
  {text:"Sentencias", value:3},
  {text:"Resoluciones", value:3},
  {text:"Resolución de conflictos", value:3},
  {text:"Plataformas electrónicas", value:7},
  {text:"Inteligencia artificial", value:3},
  {text:"Mecanismos alternativos", value:2},
  {text:"Presentación de demandas", value:5},
  {text:"Presentación de promociones", value:4},
  {text:"Identificación de personas", value:3},
  {text:"Seguridad", value:5},
  {text:"Trámite judicial", value:6},
  {text:"Sistema de citas", value:2},
  {text:"Grado de avance", value:4},
  {text:"Comunicación entre juzgado y partes", value:6},
  {text:"Comunicación entre juzgados", value:2},
  {text:"Comunicación interna del juzgado", value:2},
  {text:"Audiencias", value:6},
  {text:"Transparencia", value:6},
  {text:"Capacitación", value:2},
  {text:"Protocolos", value:4},
  {text:"Sistematización de información", value:4},
  {text:"Actuarios", value:2},
  {text:"Indicadores", value:3},
  {text:"Busqueda de información", value:5},
  {text:"Ejecución de sentencias", value:4},
  {text:"Datos abiertos", value:2},
  {text:"Exhortos", value:2},
  {text:"Firma electrónica", value:2},
  {text:"Firma Digital", value:2},
  {text:"Expediente electrónico", value:5},
  {text:"Videoconferencias", value:2},
  {text:"Lineamientos", value:3},
  {text:"Vencimiento de los plazos", value:2},
  {text:"Georreferenciación", value:3},
  {text:"Buscadores de sentencias", value:2},
  {text:"Jurisprudencia", value:2},
  {text:"Legislación", value:2},
  {text:"Pagos de pensión", value:2},
  {text:"Derecho penal", value:2},
  {text:"Sistema de gestión penal", value:2},
  {text:"Registro de asistencia", value:2},
  {text:"Libertad bajo caución", value:2},
  {text:"Notificación a las partes", value:2},
  {text:"Aviso de privacidad", value:2},
  {text:"Celebración de actos y contratos", value:2},
  {text:"Trabajo a distancia", value:2},
  {text:"Protección de derechos", value:2},
  {text:"Audiencia virtual", value:2},
  {text:"Justicia abierta", value:2},
  {text:"Publicidad de audiencias", value:2},
  {text:"Sistema de gestión", value:2},
  {text:"Juicios en línea", value:2},
  {text:"Prerregistro de demandas", value:2},
  {text:"Cosmos", value:2},
  {text:"Oficina Judicial Virtual", value:2},
  {text:"Actuarius", value:2},
  {text:"Juzgados en línea", value:2},
  {text:"Lenguaje claro", value:3},
  {text:"TEPJF", value:2},
  {text:"", value:0},
];
