/* eslint-disable complexity */
import React from "react";
import TemaProceso from "../TemaProceso";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const arrow = {
  position: 'absolute',
  color: "#F2C822",
  fontSize: "6rem",
};
  

export default function Proceso(props) {
  const { principal, subtemas, num, next, setBack} = props;
  return (
    <div className="proceso">
      {next ? (
        <div className="link">
          <ArrowForwardIcon color="warning" sx={arrow} />
        </div>
      ) : null}
      <div className="faseProceso">
        {
          next ? <ArrowForwardIcon color="warning" sx={arrow}/> : null
        }
        <TemaProceso texto={principal} tipo={1} num={num} numfase={num} />

        <div className="subtemas" style={{flexWrap: "nowrap"}}>
          {subtemas.map((subtema, index) => {
            return (
              <TemaProceso
                key={index}
                texto={subtema.titulo}
                tipo={2}
                href={subtema.href}
                setBack={setBack}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
