import React from "react";
import Slide from "../Slide";

import icon32 from "../icons/icon3.2.png";
import icon321 from "../icons/icon3.2.1.png";
import icon322 from "../icons/icon3.2.2.png";

export default function SubTemaTresDos(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        prev="#/3.0/Diseño centrado en el usuario"
        subtemas={[
          { texto: "Métodos para la generación de ideas", num: "3.2.1" },
          { texto: "Priorización de ideas", num: "3.2.2" },
        ]}
        icon={icon32}
        titulo="Idear nuevas soluciones"
        subtitulo="Idear nuevas soluciones"
        numSubtitulo="3.2"
        cuerpo={[
          "Una vez que se conocen las dinámicas del sistema y a sus usuarios, es hora de generar soluciones. Durante esta fase, el objetivo es contar con el mayor número de ideas posibles e ir descartándolas hasta definir cuáles son las más prometedoras.",
          "Algunas reglas generales para mejorar el proceso de generación de ideas son",
        ]}
        listCuerpo={[
          "No juzgar las ideas de otros. ",
          "Alentar ideas no convencionales.",
          "Construir sobre las ideas de otros.",
          "Ser generoso con las ideas.",
          "Mantenerse enfocado en el tema.",
          "Mantener una sola conversación.",
          "Ser visual.",
          "Generar el mayor número de ideas posibles",
        ]}
        typeList="number"
      />
      <Slide
        setBack={setBack}
        prev="#/3.2/Idear nuevas soluciones"
        icon={icon321}
        titulo="Idear nuevas soluciones"
        subtitulo="Métodos para la generación de ideas"
        numSubtitulo="3.2.1"
        cuerpo="Algunos de los métodos que pueden ayudar al equipo de diseño a generar diversas ideas son: "
        cards={[
          {
            titulo: "Lluvia de ideas",
            cuerpo:
              "Este método consiste en generar una serie de ideas a partir de una pregunta o concepto. Se recomienda que cada persona tenga tiempo para exponer la idea antes de escribirla.",
          },
          {
            titulo: "Top cinco",
            cuerpo:
              "Cada integrante del equipo generar cinco ideas, cada una de ellas se clasifica de acuerdo con su similitud, esta práctica sirve para descubrir ideas, identificar patrones y establecer una estrategia en torno a estos",
          },
          {
            titulo: "Seis sombreros para pensar",
            cuerpo:
              "Durante este ejercicio se le pide a un grupo de personas analizar un problema bajo seis perspectivas diferentes, que en este caso son representadas por seis sombreros de colores distintos (De Bono, 1999):",
          },
          {
            titulo: "¿Cómo podemos…?",
            cuerpo:
              "El objetivo de este ejercicio es retomar las necesidades de los usuarios que se han identificado durante la fase de descubrimiento y formularlas a través de preguntas para que el equipo las conteste. Este método ayuda a convertir los retos en oportunidades (IDEO, 2015).",
          },
        ]}
        typeListCard={3}
      />
      <Slide
        setBack={setBack}
        prev="#/3.2/Idear nuevas soluciones"
        icon={icon322}
        titulo="Idear nuevas soluciones"
        subtitulo="Priorización de ideas"
        numSubtitulo="3.2.2"
        cuerpo="Una vez que se ha generado un buen número de ideas, es momento de priorizarlas de acuerdo con su relevancia. Se pueden utilizar distintos métodos, por ejemplo:"
        listCuerpo={[
          "En lugar de hacer una clásica votación, se puede dar a los miembros del grupo un número específico de votos (por ejemplo, 10) para que puedan distribuirlos entre todas las ideas e incluso dar más de un voto a una de ellas.",
          "Otra manera es utilizar una escala para dar una calificación a cada idea; es decir, se le pide a cada persona dar una calificación entre -2 y +2 a cada una de las ideas y después se promedian los resultados para identificar la idea con mejor calificación (TISDD, s/f).",
          "Otra buena estrategia es mediante una matriz de dificultad e importancia. Para ello, es necesario dividir la matriz en cuatro sectores dependiendo de su nivel de dificultad e importancia. De esta manera, las ideas se discuten entre el equipo para poder localizarlas en algún sector de la matriz según su viabilidad e idoneidad (Hagan, 2015)",
        ]}
        casosDeEstudio={[
          "Laboratorio de Diseño Legal de la Escuela de Derecho de la Universidad de Stanford: priorización de ideas",
        ]}
      />
    </>
  );
}
