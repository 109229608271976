import { Close } from "@mui/icons-material";
import { Button, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";

import { fichas } from "../../utils/fichasTecnicas";
import { theme } from "../../utils/themeMUI";
import { BtnDownloadFicha } from "./Buttons";

export default function FichaTecnicaWrapper(props) {
  const { ficha, entidadFed, handleClose } = props;
  const [fichaSelect, setFichaSelect] = useState("");

  useEffect(() => {
      Object.keys(ficha).length > 0 && setFichaSelect(fichas[ficha]);

  }, [ficha, entidadFed]);

  return (
    <div className="fichaTecnicaWrapper">
      <h1 style={{ fontSize: "3rem", textAlign: 'center', display: 'inline' }}>{entidadFed}
        <div style={{ position: 'absolute', right: '20px', top: '10px' }}>
          <ThemeProvider theme={theme}>
            <Button onClick={handleClose} variant="contained" color="info-2" size="small">
              <Close fontSize="small" />
            </Button>
          </ThemeProvider>
        </div>
      </h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <BtnDownloadFicha texto="Descargar" href={fichaSelect} />
      </div>
      <div className="fichaTecnica">
        <Iframe
          src={fichaSelect}
          width="100%"
          height="100%"
          title="hola"
          frameBorder={0}
        />
      </div>
    </div>
  )
}
