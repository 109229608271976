import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const Toast = (props) => {
	const { message, open, severity, setOpen } = props;

	return(
		<>
			<Snackbar open={open} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center'}} onClose={() => setOpen(false)} >
				<Alert severity={severity}>
					{message}
				</Alert>
			</Snackbar>
		</>
	);
}

export default Toast;