import React from "react";
import Slide from "../Slide";

import icon30 from "../icons/icon3.0.png";
import icon31 from "../icons/icon3.1.png";
import icon311 from "../icons/icon3.1.1.png";
import icon3111 from "../icons/icon3.1.1.1.png";
import icon3112 from "../icons/icon3.1.1.2.png";
import icon312 from "../icons/icon3.1.2.png";
import icon3121 from "../icons/icon3.1.2.1.png";
import icon3122 from "../icons/icon3.1.2.2.png";
import icon313 from "../icons/icon3.1.3.png";
import icon314 from "../icons/icon3.1.4.png";
import icon315 from "../icons/icon3.1.5.png";

export default function SubTemaTresUno(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        prev="#0/0"
        subtemas={[
          {
            texto: "Entender las necesidades de las personas usuarias",
            num: "3.1",
          },
          { texto: "Idear nuevas soluciones", num: "3.2" },
          { texto: "Prototipado", num: "3.3" },
          { texto: "Escalar las ideas", num: "3.4" },
          { texto: "Implementación", num: "3.5" },
          { texto: "Evaluación", num: "3.6" },
        ]}
        icon={icon30}
        titulo="Desarrollo de intervenciones para la transformación"
        subtitulo="Diseño centrado en el usuario"
        numSubtitulo="3.0"
        cuerpo="El diseño centrado en el usuario, también llamada Metodología del pensamiento del diseño o design thinking tiene como objetivo generar un entendimiento profundo sobre las necesidades de las personas usuarias para proponer soluciones que permitan incorporar estas perspectivas. A través de esta metodología es posible crear procesos, herramientas o servicios completamente nuevos o rediseñar los ya existentes sin importar que tengan un componente tecnológico o no.Esta metodología se compone de las siguientes etapas"
      />
      <Slide
        setBack={setBack}
        prev="#/3.0/Diseño centrado en el usuario"
        subtemas={[
          { texto: "Crear equipo de intervenciones", num: "3.1.1" },
          {
            texto: "Identificar las necesidades de las y los usuarios",
            num: "3.1.2",
          },
          { texto: "Analizar la información recabada", num: "3.1.3" },
          { texto: "Presentar los hallazgos", num: "3.1.4" },
          {
            texto:
              "Retos particulares de los poderes judiciales respecto de la identificación de necesidades de las y los usuarios",
            rename: "Retos particulares de los poderes judiciales",
            num: "3.1.5",
          },
        ]}
        icon={icon31}
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Entender las necesidades de las personas usuarias"
        numSubtitulo="3.1"
        cuerpo="Esta etapa consiste en entender las perspectivas de las y los usuarios para crear soluciones que respondan a sus necesidades. Los pasos son los siguientes."
      />
      <Slide
        setBack={setBack}
        prev="#/3.1/Entender las necesidades de las personas usuarias"
        subtemas={[
          { texto: "Perfiles multidisciplinarios", num: "3.1.1.1" },
          {
            texto: "Establecimiento de pautas para el proceso",
            num: "3.1.1.2",
          },
        ]}
        icon={icon311}
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Crear equipo de intervenciones"
        numSubtitulo="3.1.1"
        cuerpo="Es imoportante contar con un equipo que será el encargado de diseñar y ejecutar la intervención."
      />
      <Slide
        setBack={setBack}
        prev="#/3.1.1/Crear equipo de intervenciones"
        icon={icon3111}
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Perfiles multidisciplinarios"
        numSubtitulo="3.1.1.1"
        cuerpo="El equipo debe estar conformado por perfiles multidisciplinarios con diversas habilidades y perspectivas distintas que permitan analizar y solucionar problemas desde diferentes ángulos. En algunos poderes judiciales el encargado de realizar el proceso es el Departamento de Tecnología e Innovación.
        Incluir en el equipo a funcionarios de distintos niveles aportaría su experiencia técnica sobre el sistema y los procesos y su conocimiento de los distintos tipos de usuarios internos, complementando los saberes de los perfiles tecnologicos. "
      />
      <Slide
        setBack={setBack}
        prev="#/3.1.1/Crear equipo de intervenciones"
        icon={icon3112}
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Establecimiento de pautas para el proceso"
        numSubtitulo="3.1.1.2"
        cuerpo="Es importante que el grupo encargado del diseño de las intervenciones tome conciencia sobre algunas pautas porque al hacerlo se puede facilitar el proceso e impulsar un efecto a largo plazo a fin de que se vaya transformando la cultura organizacional y se dé cabida a entornos más colaborativos e innovadores, en los que experimentar sea considerado una forma más de resolver problemas, algunas de estas pautas son:"
        listCuerpo={[
          "Tener confianza en nuestra intuición y perseverar hasta encontrar la solución al reto que se presente, reconociendo el potencial creativo de todas las personas.",
          "Materializar las ideas mediante prototipos que permitan evaluar la factibilidad de las soluciones y- obtener la retroalimentación de las personas usuarias.",
          "Aceptar que los errores son parte del proceso, esta metodología nos alienta a probar ideas, evaluarlas y experimentar y es muy probable que no todo funcione adecuadamente durante el primer intento.",
          "Mostrar empatía permite llegar a un grado de entendimiento de las personas que facilita ver al mundo con sus ojos y entender la complejidad de su contexto para diseñar soluciones que puedan ser realmente efectivas.",
          "Aceptar la ambigüedad es importante recordar que el proceso permite aprender, innovar y trabajar de manera colaborativa hasta encontrar la respuesta correcta.",
          "Conservar el optimismo, creer que, aunque se aborden retos difíciles, es posible encontrar una solución innovadora.",
          "Comprender que el proceso es iterativo, este enfoque permite refinar las ideas en un proceso de prueba y error, a la vez que se recibe valiosa retroalimentación de las personas usuarias.",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/3.1/Entender las necesidades de las personas usuarias"
        subtemas={[
          { texto: "Pregunta general de la problemática", num: "3.1.2.1" },
          { texto: "Usuarios objetivo", num: "3.1.2.2" },
        ]}
        icon={icon312}
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Identificar las necesidades de las y los usuarios"
        numSubtitulo="3.1.2"
        cuerpo="El primer paso es conocer cómo funciona el sistema que queremos estudiar y quiénes están involucrados. Es decir, conocer la visión de las personas usuarias acerca de cuál es el problema, así como sus necesidades, objetivos y limitaciones. Para ello, durante esta etapa es necesario recopilar la mayor cantidad de información sobre los usuarios sin realizar algún juicio al respecto."
      />
      <Slide
        setBack={setBack}
        prev="#/3.1.2/Identificar las necesidades de las y los usuarios"
        icon={icon3121}
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Pregunta general de la problemática"
        numSubtitulo="3.1.2.1"
        cuerpo="Es necesario empezar con una idea o pregunta general sobre la problemática que se quiere resolver, el problema se irá definiendo y aterrizando poco a poco, pero empezar con una idea general evita que se enmarque de acuerdo a nuestras preconcepciones."
      />
      <Slide
        setBack={setBack}
        icon={icon3122}
        prev="#/3.1.2/Identificar las necesidades de las y los usuarios"
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Usuarios objetivo"
        numSubtitulo="3.1.2.2"
        cuerpo="Por otro lado, es importante identificar a los usuarios objetivo, es decir si los problemas impactan a usuarios internos o usuarios externos. Es posible, que la intervención requiera conocer las perspectivas de ambos grupos y para ello existen muchos métodos que pueden ayudar a conocer a los usuarios. "
        cards={[
          {
            titulo: "Entrevistas",
            cuerpo:
              "Las entrevistas son un método muy útil para recopilar información acerca de las necesidades, motivaciones, limitaciones, así como las experiencias de las personas en un contexto específico. Hay diversos tipos de entrevistas que pueden utilizarse con este fin, la entrevista contextual permite obtener información de las personas en su ambiente de trabajo, las entrevistas a profundidad permiten conocer distintas perspectivas sobre un tema específico",
          },
          {
            titulo: "Observación participante y no participante",
            cuerpo:
              "Durante la observación participante, el o la investigadora realiza una inmersión en el campo con el objetivo de establecer relaciones con los participantes, pero sin ser un elemento intrusivo en el ambiente. Este método de investigación es principalmente útil para identificar aquellos aspectos que las personas no pueden expresar en las entrevistas",
          },
          {
            titulo: "Grupos de enfoque",
            cuerpo:
              "Los grupos de enfoque son otra forma de recopilar información de usuarios, tanto externos como internos. Para realizarlos, se reúne a un grupo de aproximadamente seis a diez personas para hablar sobre sus experiencias sobre una temática específica.",
          },
        ]}
        typeListCard={2}
        secondHeader={{
          titulo: "Métodos para conocer a los usuarios",
          cuerpo:
            "Algunos métodos que permiten obtener información de primera mano son:",
        }}
      />
      <Slide
        setBack={setBack}
        icon={icon313}
        prev="#/3.1/Entender las necesidades de las personas usuarias"
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Analizar la información recabada"
        numSubtitulo="3.1.3"
        cuerpo="El siguiente paso es sistematizar y analizar la información previamente obtenida, identificando las características de los usuarios, las dinámicas, los proceso y principales problemas del sistema. Algunas herramientas que pueden ser de utilidad son las siguientes: "
        cards={[
          "Crear perfiles de usuario ayuda a sistematizar y analizar la información recolectada durante esta etapa. En cada perfil se incluye información sobre sus necesidades, habilidades, limitantes, preferencias, hábitos de trabajo, etc. permitiendo identificar las características o requerimientos que deben cumplir las intervenciones que se están diseñando. Se recomienda crear de tres a siete perfiles representativos de la totalidad de personas entrevistadas y observadas.",
          "Un mapa de procesos identifica de principio a fin, un proceso o experiencia. Es importante definir la perspectiva desde la cual se transitará, se debe definir la escala, la duración de la experiencia las etapas que lo componen y los pasos específicos a seguir en cada una de ellas. Este método es útil tanto si se quieren mapear procesos actuales como si se quiere imaginar procesos ideales. ",
          "Es una lista que permite identificar las características que una solución debe tener para satisfacer las necesidades del usuario. Una vez que se haya analizado y sistematizado la información por medio de estos materiales, es necesario identificar el problema central que se quiere resolver. Una vez identificado este, es importante plantearlo en un resumen que se puede construir con los siguientes elementos: a) el tipo de usuario o usuarios a los que estará dirigida la intervención; b) el problema, y c) por qué es importante resolverlo (Hagan, 2015).",
        ]}
      />
      <Slide
        setBack={setBack}
        icon={icon314}
        prev="#/3.1/Entender las necesidades de las personas usuarias"
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Presentar los hallazgos"
        numSubtitulo="3.1.4"
        cuerpo="Es recomendable presentar al grupo de trabajo, compuesto por los actores clave, todos los hallazgos derivados de la investigación. Esta comunicación es valiosa dado que representa una oportunidad de generar “consenso y compromiso por parte de cada uno de los actores clave antes de proceder
      Goodwin (2009) propone la siguiente estructura:

      - Introducción con objetivos, cronograma, enfoque e información de contacto. 
      - Explicación de métodos y actividades utilizados.
      - Descripción de principales problemas y patrones identificados.
      - Características principales de usuarios.
      - Presentar escenarios del proceso ideal.
      - Requisitos de cada escenario.
      - Propuesta de plan de trabajo."
      />
      <Slide
        setBack={setBack}
        prev="#/3.1/Entender las necesidades de las personas usuarias"
        icon={icon315}
        titulo="Entender las necesidades de las personas usuarias"
        subtitulo="Retos particulares de los poderes judiciales respecto de la identificación de necesidades de las y los usuarios"
        numSubtitulo="3.1.5"
        cuerpo="Los poderes judiciales son instituciones con dinámicas complejas. Cuando se realiza un proceso de reflexión acerca de las necesidades de los usuarios dentro los poderes judiciales, es indispensable anticipar los retos a los que nos enfrentaremos. 

      Uno de ellos, es la independencia de los juzgadores, ya que cada juzgador es libre de decidir sobre su manera de trabajar dentro de los límites que marca la ley, cada órgano jurisdiccional tiene una forma de trabajo diferente. Por otro lado, sumando que el funcionamiento de los juzgados depende del contexto en el que están ubicados. 

      Estas diferencias tienen un gran impacto en la cantidad de información que se debe recopilar y puede elevar la complejidad del proceso, los costos y el tiempo de ejecución. Sin embargo, se pueden implementar estrategias para facilitar el proceso. Por ejemplo el Poder Judicial del Estado de México ha conformado equipos de trabajo por materia a través de la identificación de funcionarios de distintos niveles, obteniendo información relevante, aprovechando la capacidad de liderazgo y su potencial para servir de capacitadores o promotores de las nuevas metodologías desarrolladas."
      />
    </>
  );
}
