/* eslint-disable no-magic-numbers */
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Checkbox } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useLabel } from "../../shared/CustomUtilHooks";
import { filtrosNube } from './estructuraFiltrosNube';

export default function MenuFiltrosNube(props){
	const { setFilters, filters } = props;
	const createLabel = useLabel();
	const [selectedCollapse, setSelectedCollapse] = React.useState("");
	const [checked, setChecked] = React.useState([]);

	const handleToggle = (subFiltroValue) => () => {
		const currentSubFiltro = checked.indexOf(subFiltroValue);
		const newChecked = [...checked];

		if (currentSubFiltro === -1) {
			newChecked.push(subFiltroValue)
		} else {
			newChecked.splice(currentSubFiltro, 1);
		}

		setFilters(newChecked);
		setChecked(newChecked);
	}

	useEffect(()=> {
		setFilters(filters);
		setChecked(filters);
	}, [filters])

	return (
		<List component="nav" aria-labelledby="nested-list-subheader">
			{ Object.keys(filtrosNube).map(filtro => {
				return (
					<List key={`list-${filtro}`} dense>
						<ListItemButton onClick={() => setSelectedCollapse(selectedCollapse === filtro ? "" : filtro)} dense>
							<ListItemText>
								<h3 style={{fontSize: 16, textAlign: 'left', lineHeight: '30px'}}>{createLabel(`filtrosNube.${filtro}`)}</h3>
							</ListItemText>
							{selectedCollapse === filtro ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={selectedCollapse === filtro} timeout="auto">
							<List key={filtro} dense>
								{ filtrosNube[filtro].map(subFiltro => {
									return (
										<ListItemButton key={`list-item-button-${subFiltro}`} onClick={handleToggle(subFiltro)} dense>
											<ListItemIcon>
												<Checkbox
													disableRipple
													checked={checked.indexOf(subFiltro) !== -1}
													inputProps={{ 'aria-labelledby': subFiltro }}
												/>
											</ListItemIcon>
											<ListItemText id={subFiltro}>{subFiltro}</ListItemText>
										</ListItemButton>
									)
								}) }
							</List>
						</Collapse>
					</List>
				)
			}) }
		</List>
	)
}