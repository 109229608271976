export const logos = {
	"MX.AG": "http://drive.google.com/uc?export=view&id=1Vnir7oiI8IpBucmKnpi4d9kOgUbcwju0",
	"MX.BC": "http://drive.google.com/uc?export=view&id=1Q42a1FZfWGiKeopfnlXPk8TWXx0qQKEu",
	"MX.BS": "http://drive.google.com/uc?export=view&id=1aJ-5BKBygykK57ohg7h90QIvrlS4GJ5N",
	"MX.CH": "http://drive.google.com/uc?export=view&id=1xZ62jYPH2CzBUkGXEHaVhUiaMiltZjOM",
	"MX.CS": "http://drive.google.com/uc?export=view&id=1hmdV7MSLlGpYPhvcY3i-AaFpTa7Re2DE",
	"MX.CM": "http://drive.google.com/uc?export=view&id=1kWMn0bXoR2flrbN9vq4cVjtJRPXdUG8C",
	"MX.DF": "http://drive.google.com/uc?export=view&id=1nnDygQgjkO0CR15ALChkeyyVg2MWRymV",
	"MX.CO": "http://drive.google.com/uc?export=view&id=1LUDtJyJg0sfkZZCacpZXZq9y6A81rSAd",
	"MX.CL": "http://drive.google.com/uc?export=view&id=16on95SUw0IWpnnJpmaPFCRuquXESuFRI",
	"MX.DG": "http://drive.google.com/uc?export=view&id=1_ngTnVaKGRt9J_eFmj-b2DsLIAT3Opej",
	"MX.GR": "http://drive.google.com/uc?export=view&id=16GnAiZEn0kFd3vb3rllydCKjuLz41M2T",
	"MX.GJ": "http://drive.google.com/uc?export=view&id=1OYxwRRwD8wmjsWmycDm03JCmnBsWHs3F",
	"MX.HG": "http://drive.google.com/uc?export=view&id=195GSBHhxnj0NhHG_wXlvhLTjcQcNlXhw",
	"MX.JA": "http://drive.google.com/uc?export=view&id=1e9YsMWKQtn1ryGVSMg5QNdIO1FmZ42j1",
	"MX.MI": "http://drive.google.com/uc?export=view&id=1ChSivAjVZ7qAzZHf8QopFPfDXsQsRq77",
	"MX.MX": "http://drive.google.com/uc?export=view&id=1J2QAF5b_-rwYXT5eXzyoGnCmOqiwKICY",
	"MX.MO": "http://drive.google.com/uc?export=view&id=1JAGtUqd_PVJnn7iXchwwRnwMy61qTSXl",
	"MX.NA": "http://drive.google.com/uc?export=view&id=1CWS5E_kw-gOX5IFuTlwhwxUCpHDoWVkU",
	"MX.NL": "http://drive.google.com/uc?export=view&id=1XoDjYhgyUiimuE6xFYMQod1QRDrUOGxe",
	"MX.OA": "http://drive.google.com/uc?export=view&id=1jA0lfXe96vAk7voI-dkzcsyZFl8vAIH9",
	"MX.PU": "http://drive.google.com/uc?export=view&id=1dcvYaR3dUU6Co4SpiR_ihCfrYWrw1rYA",
	"MX.QR": "http://drive.google.com/uc?export=view&id=1euxTWmymYeVRAFir100C86Z2g5PonPZ9",
	"MX.QT": "http://drive.google.com/uc?export=view&id=10vakyMzDq8YH4H7h-9JAYj3lO-Y7R_oY",
	"MX.SI": "http://drive.google.com/uc?export=view&id=1m5SnSvBjoRU6kWb8wHpksr_Q-MQCMHm-",
	"MX.SL": "http://drive.google.com/uc?export=view&id=1wJnWcvWOoiqVhrnfhF7qvBmK94yyZtjl",
	"MX.SO": "http://drive.google.com/uc?export=view&id=1-ai6nSYtZLjwruRUNMtfNS6BsJ-B9Xpy",
	"MX.TB": "http://drive.google.com/uc?export=view&id=13RAWEBJ83nFSrNwyxu_0l7aALQLJje1I",
	"MX.TL": "http://drive.google.com/uc?export=view&id=1hTenk9WN0iKO7o9R_TqsMu6izXuGUj7y",
	"MX.TM": "http://drive.google.com/uc?export=view&id=1k5wbbuC0pXIkzuaaXAZq3Q9nkguW9kde",
	"MX.VE": "http://drive.google.com/uc?export=view&id=1_tWSzNOSTyJZvAWkeVRBfRYdl8t_XUlW",
	"MX.YU": "http://drive.google.com/uc?export=view&id=1LSNMBKKb6OTXHsMbEofLhnkIEo2Am94a",
	"MX.ZA": "http://drive.google.com/uc?export=view&id=1Ap6dB7dKB3y8DGreBVzd7ft67FHnDOsg",
  };