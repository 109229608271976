import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MapWrapper from "./components/mapaDeMexico/MapWrapper";
import DiagramWrapper from "./components/diagramaInteractivo/DiagramWrapper";
import WordCloudWrapper from "./components/nubePalabras/WordCloudWrapper";
import LogoJD from "./logos/Logo-JD.png";
import LogoMXE from "./logos/Logo-MXE.png";

function App() {
  return (
    <div>
      <a
        href="https://justiciadigital.mexicoevalua.org/"
        className="logoPrincipal"
        style={{ borderRadius: "0 0 30px 0", left: 0 }}
      >
        <img src={LogoJD} alt="" />
      </a>
      <a
        href="https://www.mexicoevalua.org/"
        className="logoPrincipal"
        style={{ borderRadius: "0 0 0 30px", right: 0 }}
      >
        <img src={LogoMXE} alt="" />
      </a>
      <BrowserRouter>
        <Routes>
          <Route path={"/mapaDeMexico"} element={<MapWrapper />} />
          <Route path={"/diagramaInteractivo"} element={<DiagramWrapper />} />
          <Route path={"/nubeDePalabras"} element={<WordCloudWrapper />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
