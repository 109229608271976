import React from "react";
import Slide from "../Slide";

import icon22 from "../icons/icon2.2.png";
import icon221 from "../icons/icon2.2.1.png";
import icon222 from "../icons/icon2.2.2.png";
import icon223 from "../icons/icon2.2.3.png";

export default function SubtemaDos(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        icon={icon22}
        prev="#/0/0"
        titulo="Planificación del proceso"
        subtitulo="Plan de desarrollo estratégico"
        numSubtitulo="2.2"
        cuerpo={[
          "El diseño de un plan de desarrollo estratégico es uno de los principales pasos en el proceso de transformación. Este plan debe ser construido tomando en cuenta la situación actual del Poder Judicial.",
          "Para que su elaboración sea un éxito, recomendamos tener presente las siguientes etapas: ",
        ]}
        subtemas={[
          { texto: "Diagnostico de desarrollo tecnológico", num: "2.2.1" },
          { texto: "Elaboración de plan de trabajo", num: "2.2.2" },
          { texto: "Identificación de objetivos", num: "2.2.3" },
        ]}
      />

      <Slide
        setBack={setBack}
        icon={icon221}
        prev="#/2.2/Plan de desarrollo estratégico"
        titulo="Planificación del proceso"
        subtitulo="Diagnostico de desarrollo tecnológico"
        numSubtitulo="2.2.1"
        cuerpo="Este diagnóstico debe incluir una evaluación del nivel actual de desarrollo tecnológico de la institución, entrevistas a personas que ocupan puestos jurisdiccionales y administrativos en mandos altos, medios y operativos, así como a usuarios, para detectar las necesidades de la institución, sus fortalezas y sus áreas de oportunidad. Es bueno observar diversas experiencias, triunfos y fracasos, lo cual permitirá generar un mejor panorama sobre lo que puede funcionar a cada situación. "
      />
      <Slide
        setBack={setBack}
        icon={icon222}
        prev="#/2.2/Plan de desarrollo estratégico"
        titulo="Planificación del proceso"
        subtitulo="Elaboración de plan de trabajo "
        numSubtitulo="2.2.2"
        cuerpo="Después del diagnóstico es importante elaborar un plan de trabajo detallado, estableciendo la misión y visión de la institución, considerando los recursos humanos y materiales disponibles, las etapas del proceso, actividades,  responsabilidades de distintos actores, la evaluación de  resultados, entre otras."
      />
      <Slide
        setBack={setBack}
        icon={icon223}
        prev="#/2.2/Plan de desarrollo estratégico"
        titulo="Planificación del proceso"
        subtitulo="Identificación de objetivos "
        numSubtitulo="2.2.3"
        cuerpo={[
          "Identificar objetivos claros que permitan evaluar el avance y el éxito del proyecto es fundamental, los objetivos deben de ir acompañados de indicadores que permitan medir el impacto a nivel institucional.",
          "A continuación, se ofrecen algunas de las preguntas clave que pueden ayudar a construir el plan estratégico:",
        ]}
        cards={{
          "Misión y visión": [
            "¿En donde nos encontramos y hacia donde se quiere ir?",
            "¿Cuales son los objetivos y metas que queremos alcanzar?",
            "¿Qué problemas hemos identificado o han sido identificados por actores externos?",
            "¿Qué problemas queremos abordar y resolver?",
            "¿Qué problemas podemos resolver con los recursos con los que contamos o qué vemos factible conseguir?",
            "¿De que manera la resolución de los problemas que identificamos contribuiría a cumplir con la misión y visión de la institución?",
          ],
          "Análisis del contexto": [
            "¿De qué manera el proceso de transformación tecnológica que queremos emprender se inserta en la política de digitalización o modernización del gobierno?",
            "¿Cuál es el estado de la infraestructura tecnológica  existente en todo el territorio que corresponde a nuestra jurisdicción?",
            "¿Se están discutiendo reformas legislativas que pueden incentivar o desincentivar el uso de las tecnologías en la impartición de justicia o que pueden obligarnos a implementar cambios tecnológicos en algunas materias?",
          ],
          "Recursos materiales y financieros": [
            "¿Qué se puede realizar con los recursos institucionales materiales y financieros disponibles?",
            "¿Se cuenta con el financiamiento suficiente para cumplir con los objetivos?",
            "¿Qué otras fuentes de financiamiento se pueden identificar?",
            "¿Qué tipo de herramientas deben desarrollarse internamente y cuáles otras pueden contratarse?",
            "¿Cuál es el costo de desarrollar una herramienta y cuál es su costo de mantenimiento?",
          ],
          "Etapas del proceso": [
            "¿Quién estará a cargo de la ejecución y supervisión del proyecto?",
            "¿Qué etapas comprenderá este proceso? ",
            "¿En cuánto tiempo se quiere lograr?",
            "¿Qué proyectos se pueden realizar a corto plazo y cuáles otros se deben pensar a largo plazo?",
            "¿Qué probabilidades existen de que las futuras administraciones del Poder Judicial den seguimiento a lo implementado por la actual administración?",
            "¿Qué aspectos debemos prever o flexibilizar al momento de desarrollar herramientas tecnológicas que nos permitan escalarlas e interconectarlas entre sí en un futuro?",
          ],
          "Gestión de cambio": [
            "¿Qué obstáculos y resistencias dentro y fuera de la institución se podrían presentar?",
            "¿Cómo se abordará el cambio cultural dentro y fuera de la institución?",
            "¿Qué aliados podemos encontrar para superar estosobstáculos y resistencias?",
          ],
          "Evaluación de los resultados": [
            "¿Cómo se evaluarán los resultados y avances?",
            "¿Qué indicadores específicos y metas es necesario establecer para medir el alcance de los proyectos o intervenciones concretas?",
            "¿Qué indicadores generales y metas es necesario establecer para medir el avance de la institución gracias a estos proyectos o intervenciones concretas",
          ],
        }}
        typeListCard={1}
      />
    </>
  );
}
