import React from "react";
import Slide from "../Slide";

import icon11 from "../icons/precondicionesNecesarias.png";
import icon111 from "../icons/icon1.1.1.png";
import icon112 from "../icons/icon1.1.2.png";
import icon113 from "../icons/icon1.1.3.png";
import icon12 from "../icons/icon1.2.png";
import icon121 from "../icons/icon1.2.1.png";
import icon122 from "../icons/icon1.2.2.png";
import icon123 from "../icons/icon1.2.3.png";
import icon124 from "../icons/icon1.2.4.png";

export default function FaseProcesoUno(props) {
  const { setBack } = props;
  return (
    <section>
      <Slide
        subtemas={[
          { texto: "Apoyo del sector político", num: "1.1.1" },
          {
            texto: "Fomentar un clima de innovación",
            num: "1.1.2",
          },
          { texto: "Continuidad", num: "1.1.3" },
        ]}
        icon={icon11}
        titulo="Precondiciones necesarias"
        subtitulo="Liderazgo innovador y abierto al cambio"
        numSubtitulo="1.1"
        cuerpo="Uno de los elementos más importantes para iniciar un proceso de transformación tecnológica es contar con un liderazgo sólido que tenga la voluntad de realizar cambios profundos y una visión estratégica que permita dotar de dirección a la institución.  En los poderes judiciales con mayor nivel de desarrollo tecnológico, el proceso de transformación inició porque en un determinado momento un presidente o presidenta del poder judicial se había interesado por el tema y lo había priorizado en su agenda de trabajo durante su gestión. Además de un liderazgo sólido, es necesario contar con los siguientes elementos"
        casosDeEstudio={[
          "Poder Judicial del Estado de México: aseguramiento de presupuesto",
        ]}
        prev="#0/0"
        setBack={setBack}
      />
      <Slide
        prev="#/1.1/Liderazgo innovador y abierto al cambio"
        titulo="Precondiciones necesarias"
        numSubtitulo={"1.1.1"}
        icon={icon111}
        subtitulo={"Apoyo del sector político"}
        cuerpo={[
          "Este tipo de apoyo es de especial relevancia para la realización de cambios normativos, la obtención de financiamiento a largo plazo y para alinear las prioridades de las instituciones involucradas. En este sentido, diversos autores señalan que es necesario evaluar el grado de apoyo y compromiso político a través del análisis del discurso, la identificación de las prioridades dentro de la agenda y la opinión pública sobre el tema. ",
        ]}
        setBack={setBack}
      />
      <Slide
        prev="#/1.1/Liderazgo innovador y abierto al cambio"
        titulo="Precondiciones necesarias"
        numSubtitulo={"1.1.2"}
        subtitulo={"Fomentar un clima de innovación"}
        icon={icon112}
        cuerpo={[
          "Una forma de fomentar la creatividad e innovación dentro de las instituciones es a través de la creación de un departamento de innovación que permita identificar y dar seguimiento a las iniciativas prometedoras. Este departamento debería visualizarse como un área robusta que permita diseñar, implementar y evaluar todo tipo de intervenciones. Otra práctica positiva en este sentido es la creación de espacios en los que el personal pueda externar sus propuestas o sugerencias, a fin de facilitar la comunicación entre éste y la dirección. Estos espacios, además, pueden ayudar a identificar ideas o iniciativas prometedoras y disminuir los obstáculos existentes dentro de las instituciones donde la toma de decisiones es más jerárquica y no ofrece posibilidades de experimentar.",
        ]}
        setBack={setBack}
      />
      <Slide
        setBack={setBack}
        prev="#/1.1/Liderazgo innovador y abierto al cambio"
        titulo="Precondiciones necesarias"
        numSubtitulo={"1.1.3"}
        subtitulo={"Continuidad"}
        icon={icon113}
        cuerpo={
          "Generalmente, los procesos de transformación tecnológica son impulsados por una persona que detenta el poder en ese momento, sin embargo, cuando concluye su gestión, los esfuerzos terminan para dar paso a los planes y objetivos de la persona que ocupa su lugar. Algunas estrategias para asegurar la continuidad de los procesos de transformación tecnológica son las siguientes: "
        }
        cards={[
          "Establecer el proceso de transformación tecnológica como un tema fundamental en el plan de desarrollo estratégico de la institución.",
          "Buscar la aprobación del plan de desarrollo estratégico por el órgano de gobierno judicial y por el pleno de magistrados.",
          "Reducir la rotación de los funcionarios administrativos también podría ayudar a la continuación del plan de transformación tecnológica.",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#0/0"
        subtemas={[
          { texto: "Capacidad de decidir", num: "1.2.1" },
          { texto: "Presupuesto", num: "1.2.2" },
          {
            texto: "Perfiles multidisciplinarios",
            href: "#/0/8",
            num: "1.2.3",
          },
          { texto: "Capacitación ", num: "1.2.4" },
        ]}
        titulo="Precondiciones necesarias"
        subtitulo="Consolidación del departamento de tecnología"
        numSubtitulo="1.2"
        icon={icon12}
        cuerpo="Contar con un departamento de tecnología consolidado es esencial para iniciar con un proceso de transformación tecnológica, esto facilitará el desarrollo de soluciones y asegurará que los sistemas desarrollados permanezcan en el Poder Judicial descartando la dependencia respecto de desarrolladores externos o terceros. 

        El departamento de tecnología debe tener la capacidad de cumplir con las exigencias del proceso, las principales características con las que debe contar este departamento son las siguientes:"
        casosDeEstudio={[
          "Poder Judicial de Tamaulipas: Consolidación del departamento de tecnología",
          "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/1.2/Consolidación del departamento de tecnología"
        titulo="Precondiciones necesarias"
        numSubtitulo={"1.2.1"}
        subtitulo={"Capacidad de decidir"}
        icon={icon121}
        cuerpo={[
          "Generalmente, los departamentos de tecnología son percibidos dentro de los poderes judiciales como áreas de soporte y apoyo. Sin embargo, es importante que esta área participe en la toma de decisiones relacionadas con el proceso de transformación tecnológica para dar seguimiento con las áreas involucradas y facilitar el desarrollo de soluciones tecnológicas. De esta manera, el desarrollo, actualización y mantenimiento de los sistemas podrá realizarse internamente en lugar de depender de un tercero, como sucede cuando se desarrollan herramientas de manera externa.",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/1.2/Consolidación del departamento de tecnología"
        titulo="Precondiciones necesarias"
        numSubtitulo={"1.2.2"}
        subtitulo={"Presupuesto"}
        icon={icon122}
        cuerpo="Es común que a estos departamentos no se les asigne un presupuesto específico o que éste sea muy reducido. La asignación de un presupuesto adecuado requiere de un gran esfuerzo de planeación institucional y de una búsqueda de oportunidades de financiamiento."
      />
      <Slide
        setBack={setBack}
        prev="#/1.2/Consolidación del departamento de tecnología"
        titulo="Precondiciones necesarias"
        numSubtitulo={"1.2.3"}
        subtitulo={"Perfiles multidisciplinarios"}
        icon={icon123}
        cuerpo="Los perfiles de las personas que integran el departamento de tecnologías, además de conocimientos técnicos deben de contar con habilidades de liderazgo, con las competencias necesarias para llevar a cabo los proyectos y cumplir con los objetivos planteados. Por otro lado, se recomienda incluir perfiles multidisciplinarios a esta área. Por ejemplo personas con formación en derecho que también tengan conocimiento sobre informática o tecnologías o viceversa."
        casosDeEstudio={[
          "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
          "Poder Judicial de Yucatán: intervención con principios de diseño centrado en el usuario",
          "Poder Judicial de la República Oriental del Uruguay: intervención con principios de diseño centrado en el usuario",
          "Poder Judicial de la República Oriental del Uruguay: implementación de intervenciones",
        ]}
      />
      <Slide
        setBack={setBack}
        prev="#/1.2/Consolidación del departamento de tecnología"
        titulo="Precondiciones necesarias"
        numSubtitulo={"1.2.4"}
        icon={icon124}
        subtitulo={"Capacitación"}
        cuerpo="Otro de los aspectos fundamentales para consolidar el área de tecnología es la capacitación constante del personal. Aunque pueden ser costosas, éstas son indispensables para actualizar al personal e identificar buenas prácticas que puedan ser adoptadas en el área."
      />
    </section>
  );
}
