import React from 'react'
import { Modal, Box } from '@mui/material';
import FichaTecnicaWrapper from './tabulador/FichaTecnicaWrapper';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'fit-content',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	padding: 4,
  };

export const ModalMapa = (props) => {
	const { open, handleClose, ficha, entidadFed, setEntidadFed } = props;
	return (	
		<Modal 
			open={open}
			onClose={handleClose}
		>
			<Box sx={style}>
				<FichaTecnicaWrapper
					ficha={ficha}
					entidadFed={entidadFed}
					setEntidadFed={setEntidadFed}
					handleClose={handleClose}
				/>
			</Box>
		</Modal>
	)
  }
  
  export default ModalMapa;
