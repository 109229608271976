export const fichas = {
	"MX.AG": "https://drive.google.com/file/d/1udL-evXteE171BfnccLFvnwvJLcJ6Cs8/preview",
	"MX.BC": "https://drive.google.com/file/d/1Wedgvfh2fdJ-l30Spe8XbwBTgzP1uyxN/preview",
	"MX.BS": "https://drive.google.com/file/d/1_rWlC_KjkCvL26nFgxIQdf28Apu5iz0-/preview",
	"MX.CH": "https://drive.google.com/file/d/1aRrvlPHV7DAutcb2Cy6cRyMTQFf_T-wt/preview",
	"MX.CS": "https://drive.google.com/file/d/1edEzR8ozshLrUEreJaErR0pLazFpWhOU/preview",
	"MX.CM": "https://drive.google.com/file/d/1AyOE70vkBXSVX_b7GUh_zmDb_YbBovjx/preview",
	"MX.DF": "https://drive.google.com/file/d/19XnrMmOGd9Jxx8QJlXAhUlfRj2MVP-n4/preview",
	"MX.CO": "https://drive.google.com/file/d/1EOX7WzNL1BIfK5T6x1GC8EBJdCdk_NfL/preview",
	"MX.CL": "https://drive.google.com/file/d/129Sc0nbpK1klfghRdjNVUW--BoiMTRhs/preview",
	"MX.DG": "https://drive.google.com/file/d/1qLdicm4hpcmh7rdRrBGty02z19Q2WpyI/preview",
	"MX.GR": "https://drive.google.com/file/d/12PhVIDYTlVgywCsxgoQPYwI5OcrwWbu-/preview",
	"MX.GJ": "https://drive.google.com/file/d/1PnmbS096LtzQ5MJSw_s4CGJVDZYf1ELH/preview",
	"MX.HG": "https://drive.google.com/file/d/1MBowGAJc__zNT7RIj9DwNrHHE2PAS84n/preview",
	"MX.JA": "https://drive.google.com/file/d/10-sNgMGawJ-goeA9Lj5TcmSv8bYMCVgi/preview",
	"MX.MI": "https://drive.google.com/file/d/1b-BDe8DJvX_1w7MEx7mZJMwR9D3XTgfR/preview",
	"MX.MX": "https://drive.google.com/file/d/1lA_Ax5XvhmNGfs80aK2o5kpDrJCxd7JX/preview",
	"MX.MO": "https://drive.google.com/file/d/1VZ8FX0Pfe6Wd9pMCpWkMJYselodWQFuQ/preview",
	"MX.NA": "https://drive.google.com/file/d/1NK8TDTjGqleWe5fbe0zV_osiiEnbV6uy/preview",
	"MX.NL": "https://drive.google.com/file/d/1tOOeE3WNXk8WgzvHeqyzcxjXZb24Hpfc/preview",
	"MX.OA": "https://drive.google.com/file/d/1Fi3n5IxqYkCiEwhBjOF2g8a2kA1NOEsX/preview",
	"MX.PU": "https://drive.google.com/file/d/1gargb-fTjkGjUYePBs0dSW54eBr5AkU7/preview",
	"MX.QR": "https://drive.google.com/file/d/1IwNS1XwVVKF6EqDxnGTkFJWo5WnGQ6ha/preview",
	"MX.QT": "https://drive.google.com/file/d/1SFOdbP2uEnAHrB3ZwZKRyfyVatvBQmae/preview",
	"MX.SI": "https://drive.google.com/file/d/1VAuGBQfJXgvpu9XrGhViQDsTYnznuws_/preview",
	"MX.SL": "https://drive.google.com/file/d/10cAcBAEdqb_46IOM56-C8rLZLM2jd8zn/preview",
	"MX.SO": "https://drive.google.com/file/d/1VzFbQ0r_4_NuCcIDO3CaPOz-3dZz-uGX/preview",
	"MX.TB": "https://drive.google.com/file/d/1i8zyK8LRDbLII-PnsDT_JkrhimL_uYwJ/preview",
	"MX.TL": "https://drive.google.com/file/d/18zCxcTUkwqQqe6CuHtY7t0OxVt-9fKng/preview",
	"MX.TM": "https://drive.google.com/file/d/1NJVBKII72JbIZD0L3fT3oVe8s2KGiFiP/preview",
	"MX.VE": "https://drive.google.com/file/d/1gUKFiSuaXlWLBnKBpJ_afFq_hHoPc4py/preview",
	"MX.YU": "https://drive.google.com/file/d/1nTCAQZ4ZY4pvC2pezb0saJG9pGiNG1dZ/preview",
	"MX.ZA": "https://drive.google.com/file/d/19uOmsSt_t5NbOYkrv8LUwd0lpK1dqhcY/preview"
  };
  
  