export const firElec = [
    [
      { name: "Cuenta con firma electrónica", tam: 1 },
      { name: "Características de la firma electrónica", tam: 6 },
      { name: "Año de implementación", tam: 1 },
      { name: "Tipo de usuario de la herramienta", tam: 5 },
      { name: "Número de firmas emitidas a usuarios", tam: 10 },
      {
        name: "Materias en las que se ha implementado la firma electrónica avanzada",
        tam: 5,
      },
    ],
    [
      { name: "", tam: 1 },
      { name: "Tipo de desarrollo", tam: 4 },
      { name: "Tipo de uso", tam: 2 },
      { name: "", tam: 1 },
      { name: "Interno", tam: 4 },
      { name: "Externo", tam: 1 },
      { name: "Interno", tam: 5 },
      { name: "Externo", tam: 5 },
      { name: "Laboral", tam: 1 },
      { name: "Penal", tam: 1 },
      { name: "Mercantil", tam: 1 },
      { name: "Familiar", tam: 1 },
      { name: "Civil", tam: 1 },
    ],
    [
      { name: "", tam: 1 },
      { name: "Propia", tam: 1 },
      { name: "Empresa privada", tam: 1 },
      { name: "FIREL", tam: 1 },
      { name: "SAT", tam: 1 },
      { name: "Interno", tam: 1 },
      { name: "Externo", tam: 1 },
      { name: "", tam: 1 },
      { name: "Magistradas(os)", tam: 1 },
      { name: "Juzgadoras(es)", tam: 1 },
      { name: "Secretarias(os) proyectistas", tam: 1 },
      { name: "Otros(as) funcionarios(as)", tam: 1 },
      { name: "", tam: 1 },
      { name: "2018", tam: 1 },
      { name: "2019", tam: 1 },
      { name: "2020", tam: 1 },
      { name: "2021", tam: 1 },
      { name: "Total 2018-2021 ", tam: 1 },
      { name: "2018", tam: 1 },
      { name: "2019", tam: 1 },
      { name: "2020", tam: 1 },
      { name: "2021", tam: 1 },
      { name: "Total 2018-2021", tam: 1 },
      { name: "", tam: 1 },
      { name: "", tam: 1 },
      { name: "", tam: 1 },
      { name: "", tam: 1 },
      { name: "", tam: 1 },
    ],
  ];