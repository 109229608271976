import React from "react";
import Slide from "../Slide";

import icon34 from "../icons/icon3.4.png";

export default function SubTemaTresCuatro(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        prev="#/3.0/Diseño centrado en el usuario"
        icon={icon34}
        titulo="Escalar las ideas"
        subtitulo="Escalar las ideas"
        numSubtitulo="3.4"
        cuerpo="Cuando la herramienta o intervención ha sido probada, evaluada y ha obtenido buena retroalimentación de las y los usuarios, es momento de escalar la solución.
          Es importante planear cuidadosamente el proceso. Al respecto, se deben definir temas de organización, como quien estará a cargo de pilotear la intervención, en qué tiempos, y qué logros se deben alcanzar en un corto, mediano y largo plazo"
      />
    </>
  );
}
