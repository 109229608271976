/* eslint-disable no-magic-numbers */
/* eslint-disable complexity */
import React from "react";
import Card from "./Card";

export default function ListCards(props) {
  const { cards, typeListCard } = props;
  console.log();

  if (typeListCard === 1)  return (
    <div className={classList(typeListCard)}>
      {
        Object.keys(cards).map((card, index) => {
          const lista = cards[card]
          return <Card key={index} encabezado={card} lista={lista} redondeado={true}/>;
        })
      }
    </div>
  )

  if (typeListCard === 2)  return (
    <div className={classList(typeListCard)}>
      {
        cards.map((card, index) => {
          return <Card key={index} titulo={card.titulo} cuerpo={card.cuerpo} redondeado={true} direccion={index%2 ? 0: 1} typeListCard={typeListCard} icono={card.icono ? card.icono : null}/>
        })
      }
    </div>
  )

  if (typeListCard === 3)  return (
    <div className={classList(typeListCard)} style={{rowGap: "0px"}}>
      {
        cards.map((card, index) => {
          return <Card key={index} titulo={card.titulo} cuerpo={card.cuerpo} redondeado={true} direccion={index%2 ? 0: 1} icono={card.icono ? card.icono : null} typeListCard={typeListCard}/>
        })
      }
    </div>
  )

  return (
    <div className={classList(typeListCard)}>
      {cards.map((card, index) => {
        return <Card key={index} card={card} num={(index+1)}/>;
      })}
    </div>
  );
}


const classList = (typeListCard) => {
  if (typeListCard === 1) return "list-cards-1";
  if (typeListCard >=2 && typeListCard <= 3) return "list-cards-2";
  return "list-cards";
}
