import * as excelJS from "exceljs";
import { saveAs } from "file-saver";
import {capImp} from "./cabeceras/capImp";
import {expElect} from "./cabeceras/expElect";
import {firElec} from "./cabeceras/firElec";
import {herraElabPubSen} from "./cabeceras/herraElabPubSen";
import {herraVidConfe} from "./cabeceras/herraVidConfe";
import {infraTec} from "./cabeceras/infraTec";
import {juiLin} from "./cabeceras/juiLin";
import {plaInterDem} from "./cabeceras/plaInterDem";
import {plaPubSen} from "./cabeceras/plaPubSen";
import {sisGest} from "./cabeceras/sisGest";

const nombresHerramientas = {
  capacitacionImpartida: 'Capacitación Impartida',
  expedienteElectronico: 'Expediente Electrónico',
  firmaElectronica: 'Firma Electrónica',
  herramientaVideoconferencias: 'Herramienta de Videoconferencias',
  herramientasElaboracionPublicaSentencias: 'Herramientas de Elaboración Pública de Sentencias',
  infraestructuraTecnologica: 'Infraestructura Tecnológica',
  juiciosLinea: 'Juicios en Línea',
  plataformaInterponerDemanda: 'Plataforma para Interponer Demandas',
  plataformaPublicacionSentencias: 'Plataforma de Publicación de Sentencias',
  sistemaGestion: 'Sistema de Gestión'
};

const excelCreator = (resultadoPoderesJudiciales) => {
  const cabecerasDePoderes = {
    capacitacionImpartida: capImp,
    expedienteElectronico: expElect,
    firmaElectronica: firElec,
    herramientaVideoconferencias: herraVidConfe,
    herramientasElaboracionPublicaSentencias: herraElabPubSen,
    infraestructuraTecnologica: infraTec,
    juiciosLinea: juiLin,
    plataformaInterponerDemanda: plaInterDem,
    plataformaPublicacionSentencias: plaPubSen,
    sistemaGestion: sisGest
  };
  
  const workbook = new excelJS.Workbook();
  workbook.creator = "Monkey-Lab";
  workbook.lastModifiedBy = "Monkey-Lab";
  workbook.created = new Date();
  workbook.modified = new Date();
  
  
  resultadoPoderesJudiciales.forEach(poderJudicial => {

    const sheet = workbook.addWorksheet(poderJudicial.poderJudicial);

    Object.keys(poderJudicial).forEach(herramienta => {
      if (herramienta !== 'poderJudicial') {

        //se agrega y da estilos a los nombres de las herramientas
        sheet.addRow([nombresHerramientas[herramienta]]).eachCell(titleCell => {
          titleCell.font = {
            name: 'Cambria', 
            size: 18,
            color: { argb: '1F497D' },
          }
        });
        sheet.lastRow.height = 25;

        //se agrega y da estilo a las cabeceras de cada tabla
        cabecerasDePoderes[herramienta].forEach(filaCabecera => {
          const nombresCabeceras = []
          filaCabecera.forEach(cabecera => nombresCabeceras.push(cabecera.name))
          sheet.addRow(nombresCabeceras).eachCell(headerCell => {
            headerCell.font = {
              color: {argb: 'FFFFFF'},
              bold: true
            }
            headerCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00868D' }
            }
            headerCell.alignment = {
              wrapText: true,
              horizontal: 'center',
              vertical: 'middle',
              shrinkToFit: true
            }
            sheet.getColumn(headerCell.col).width = 30;
          })
          sheet.lastRow.height = 42;
        })

        //se añaden los datos de cada columna
        sheet.addRow(poderJudicial[herramienta]).eachCell(informacion => {
          informacion.alignment = {
            horizontal: 'center',
            vertical: 'middle'
          }
        });
      }
    })
  })

  workbook.xlsx.writeBuffer().then(function (buffer){
    const blob = new Blob([buffer], {type: "aplication/xlsx"});
    saveAs(blob, "justiciaDigital.xlsx");
  })
  .catch((error) => console.log(error));
}
export default excelCreator;
