/* eslint-disable no-magic-numbers */
/* eslint-disable complexity */
import React from "react";

const style = {
  h1Card: {
    fontSize: 20,
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
    margin: 0
  },
};

export default function Card(props) {
  const {
    card,
    num,
    encabezado,
    lista,
    titulo,
    cuerpo,
    redondeado,
    direccion,
    icono,
    typeListCard,
  } = props;

  return (
    <div
      className="card-Mapa"
      style={{ borderRadius: redondeado ? "15px" : "", background: typeListCard === 3 ? "none" : "#C7D0D8" }}
    >
      {num ? <span>{num}</span> : null}
      {encabezado ? <div className="encabezado-2">{encabezado}</div> : null}
      {card}
      <ul>
        {lista
          ? lista.map((item, index) => {
              return <li key={index}>{item}</li>;
            })
          : null}
      </ul>

      {typeListCard === 2
        ? icono
          ? existeIcono(direccion, icono, cuerpo, titulo)
          : noExisteIcono(direccion, titulo, cuerpo)
        : null}

      {typeListCard === 3 ? (
        <div className="div-4" >
          <h1 style={style.h1Card}>{titulo}</h1>
          <p>{cuerpo}</p>
          {icono ? <div><img src={icono} alt="" style={{maxHeight: "70px"}}/></div> : null}
        </div>
      ) : null}
    </div>
  );
}

const existeIcono = (direccion, icono, titulo, cuerpo) => {
  return direccion === 0 ? (
    <>
      <div className="div-3-izq">
        <img src={icono} alt="" />
        <h1 style={style.h1Card}>{titulo}</h1>
        <p>{cuerpo}</p>
      </div>
    </>
  ) : (
    <>
      <div className="div-3-der">
        <p>{cuerpo}</p>
        <h1 style={style.h1Card}>{titulo}</h1>
        <img src={icono} alt="" />
      </div>
    </>
  );
};

const noExisteIcono = (direccion, titulo, cuerpo) => {
  return direccion === 0 ? (
    <>
      <div className="div-2-izq">
        <h1 style={style.h1Card}>{titulo}</h1>
        <p>{cuerpo}</p>
      </div>
    </>
  ) : (
    <>
      <div className="div-2-der">
        <p>{cuerpo}</p>
        <h1 style={style.h1Card}>{titulo}</h1>
      </div>
    </>
  );
};
