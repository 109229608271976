import Slide from "../Slide";
import React from "react";

import icon23 from "../icons/icon2.3.png";
import icon231 from "../icons/icon2.3.1.png";
import icon232 from "../icons/icon2.3.2.png";
import icon233 from "../icons/icon2.3.3.png";
import icon234 from "../icons/icon2.3.4.png";
import icon235 from "../icons/icon2.3.5.png";

export default function SubtemaTres(props) {
  const { setBack } = props;
  return (
    <>
      <Slide
        setBack={setBack}
        icon={icon23}
        prev="#0/0"
        titulo="Planificación del proceso"
        subtitulo="Presupuesto"
        numSubtitulo="2.3"
        cuerpo={[
          "Uno de los principales retos cuando se habla de transformaciones al interior de los poderes judiciales es el presupuesto. Su insuficiencia, los recortes presupuestales derivados de políticas de austeridad y la falta de autonomía presupuestal son algunos de los obstáculos que enfrentan los poderes judiciales.",
          "Algunas estrategias para superar este obstáculo son:",
        ]}
        subtemas={[
          { texto: "Reasignación de presupuesto", num: "2.3.1" },
          { texto: "Financiamiento", num: "2.3.2" },
          { texto: "Esquema de tecnología autosustentable", num: "2.3.3" },
          {
            texto:
              "Identificación de posibles alianzas con otros poderes/actores",
            num: "2.3.4",
          },
          { texto: "Co-creación de herramientas tecnológicas", num: "2.3.5" },
        ]}
        casosDeEstudio={[
          "Ministerio de Justicia de España: indicadores para evaluar intervenciones",
          "Poder Judicial de la República Oriental del Uruguay: consideraciones respecto al presupuesto",
          "Poder Judicial del Estado de México: aseguramiento de presupuesto",
          "Poder Judicial del Estado de México: consolidación de departamento de tecnología",
        ]}
      />

      <Slide
        setBack={setBack}
        icon={icon231}
        prev="#/2.3/Presupuesto"
        titulo="Planificación del proceso"
        subtitulo="Reasignación de presupuesto"
        numSubtitulo="2.3.1"
        cuerpo={[
          "Un primer paso podría ser reasignar recursos de otros rubros o departamentos al área de tecnología. Esto puede generar resistencias internas, por esta razón es de utilidad evaluar los ahorros que pueden generar las soluciones tecnológicas implementadas.",
          "Algunos poderes judiciales han reportado ahorros significativos en papel o tóner, cuando parte de los expedientes ya no se imprimen o copian. La previsión plurianual de estas asignaciones y ahorros puede permitir identificar recursos para realizar este tipo de proyectos y superar las resistencias internas.",
        ]}
        casosDeEstudio={[
          "Poder Judicial del Estado de México: aseguramiento de presupuesto",
          "Ministerio de Justicia de España: indicadores para evaluar intervenciones",
        ]}
      />

      <Slide
        setBack={setBack}
        icon={icon232}
        prev="#/2.3/Presupuesto"
        titulo="Planificación del proceso"
        subtitulo="Financiamiento"
        numSubtitulo="2.3.2"
        cuerpo={[
          "Otra estrategia para obtener fondos es mediante donaciones o financiamiento otorgado por agencias de cooperación internacionales, bancos de desarrollo u otras organizaciones.",
          "Es indispensable evaluar la sostenibilidad de los proyectos a largo plazo y trazar estrategias para asegurar su continuidad una vez que el financiamiento llegue a su fin, porque los costos relacionados con la implementación de soluciones tecnológicas no solamente derivan de las herramientas mismas, sino de su mantenimiento.",
        ]}
      />
      <Slide
        setBack={setBack}
        icon={icon233}
        prev="#/2.3/Presupuesto"
        titulo="Planificación del proceso"
        subtitulo="Esquema de tecnología autosustentable"
        numSubtitulo="2.3.3"
        cuerpo={[
          "Cobrar una cuota a algunos usuarios —como ya se hace en algunos países— para el uso de algunas herramientas tecnológicas, es una opción para financiar el desarrollo tecnológico, de esta manera, este apoyo puede ser entregado a los poderes judiciales como presupuesto suplementario.",
          "Sin embargo, es importante cuidar que estos cobros no acaben impidiendo el acceso a la justicia, sobre todo a las personas que no cuentan con los recursos necesarios.",
        ]}
      />

      <Slide
        setBack={setBack}
        icon={icon234}
        prev="#/2.3/Presupuesto"
        titulo="Planificación del proceso"
        subtitulo="Identificación de posibles alianzas con otros poderes/actores"
        numSubtitulo="2.3.4"
        cuerpo={[
          "Cobrar una cuota a algunos usuarios —como ya se hace en algunos países— para el uso de algunas herramientas tecnológicas, es una opción para financiar el desarrollo tecnológico, de esta manera, este apoyo puede ser entregado a los poderes judiciales como presupuesto suplementario.",
          "Sin embargo, es importante cuidar que estos cobros no acaben impidiendo el acceso a la justicia, sobre todo a las personas que no cuentan con los recursos necesarios.",
        ]}
        casosDeEstudio={[
          "Poder Judicial del Estado de México: aseguramiento de presupuesto",
        ]}
      />

      <Slide
        setBack={setBack}
        icon={icon235}
        prev="#/2.3/Presupuesto"
        titulo="Planificación del proceso"
        subtitulo="Co-creación de herramientas tecnológicas"
        numSubtitulo="2.3.5"
        cuerpo={[
          "Una de las interrogantes a despejar cuando se requiere de la utilización de un software es si éste debe comprarse o desarrollarse internamente. Si bien, inicialmente, su compra a terceros aparenta ser más asequible, a largo plazo su costo-beneficio resulta menor debido a los costos de mantenimiento y actualización. Esto abre un abanico de oportunidad en torno al codesarrollo de sistemas de código abierto ya que permite que los sistemas puedan ser compartidos entre los poderes judiciales sin costos de licenciamiento.",
          "Esto ya es una realidad en países como Brasil, Nigeria, Trinidad y Tobago, Zambia, Namibia, Guayana y Barbados.",
        ]}
        casosDeEstudio={[
          "Poder Judicial del Estado de México: aseguramiento de presupuesto",
        ]}
      />
    </>
  );
}
