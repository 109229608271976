export const plaInterDem = [
    [
      {
        name: "¿Cuenta con una plataforma para enviar y recibir demandas y/o promociones?",
        tam: 1,
      },
      { name: "Tipo de plataforma", tam: 2 },
      { name: "Año de implementación", tam: 1 },
      { name: "Mecanismo de acceso a la plataforma", tam: 2 },
      {
        name: "¿Se utiliza la firma electrónica avanzada para firmar demandas y/o promociones?",
        tam: 1,
      },
      { name: "Número de demandas presentadas electrónicamente", tam: 5 },
      { name: "Número de promociones presentadas electrónicamente", tam: 5 },
      {
        name: "Materias en las que es posible interponer demandas y/o promociones",
        tam: 5,
      },
    ],
    [
      { name: "", tam: 1 },
      { name: "Interponer demandas ", tam: 1 },
      { name: "Interponer promociones", tam: 1 },
      { name: "", tam: 1 },
      { name: "Usuario y contraseña", tam: 1 },
      { name: "Firma electrónica avanzada", tam: 1 },
      { name: "", tam: 1 },
      { name: "2018", tam: 1 },
      { name: "2019", tam: 1 },
      { name: "2020", tam: 1 },
      { name: "2021 (enero a 30 de junio)", tam: 1 },
      { name: "Total 2018-2021", tam: 1 },
      { name: "2018", tam: 1 },
      { name: "2019", tam: 1 },
      { name: "2020", tam: 1 },
      { name: "2021 (al 30 de junio)", tam: 1 },
      { name: "Total de promociones 2018-2021", tam: 1 },
      { name: "Laboral", tam: 1 },
      { name: "Penal", tam: 1 },
      { name: "Mercantil", tam: 1 },
      { name: "Familiar", tam: 1 },
      { name: "Civil", tam: 1 },
    ],
  ];