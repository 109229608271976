/* eslint-disable complexity */
import React, { useState } from "react";
import { useLabel } from "../../shared/CustomUtilHooks";
import Map from "./mapa/Map";
import TabuladorMapa from "./tabulador/TabuladorMapa";
import MenuFiltros from "./tabulador/filtros/MenuFiltros";
import { Stack, ThemeProvider } from "@mui/system";
import { Button, AppBar, Toolbar, Card, Menu, IconButton } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { BtnBuscar } from "../../components/mapaDeMexico/tabulador/Buttons";
import { getHerramientasPorEstado } from "../utils/api";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {theme} from '../utils/themeMUI'
import { Close } from "@mui/icons-material";

export default function MapWrapper() {
  const [ficha, setFicha] = useState("");
  const [entidadFed, setEntidadFed] = useState("");
  const [herramienta, setHerramienta] = useState("Comparador");
  const [fijar, setFijar] = useState(false);
  const [comparadores, setComparadores] = useState([]);
  const createLabel = useLabel();
  const [searchResults, setSearchResults] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [cleanFilters, setCleanFilters] = useState(false);
  const [filtrosAct, setFiltrosAct] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastOptions, setToastOptions] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openFilterMenu = Boolean(anchorEl);

  document.title = "Mapa de Mexico";

  const optionState = (entidad) => {
    if (!fijar) {
      setFicha(entidad.id);
      setEntidadFed(entidad.properties.name);
    }
  };

  const clickEntidad = (entidad) => {
    setFijar(true);
    setFicha(entidad.id);
    setEntidadFed(entidad.properties.name);
  };

  const buscar = async (filtros) => {
    setShowLoader(true);
    getHerramientasPorEstado(filtros)
      .then((data) => {
        setSearchResults(data?.data?.data?.results);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowToast(true);
        setToastOptions({
          message:
            error.response.data.message === "Empty body"
              ? "No se seleccionó ningún filtro"
              : error.response.data.message,
          severity: "error",
        });
        setShowLoader(false);
      });
  };

  return (
    <>
      <div className="mapWrapper">
        <div className="upperSideContainer">
          <div className="mapContainer">
            <Map
              optionState={optionState}
              clickEntidad={clickEntidad}
              herramienta={herramienta}
              ficha={ficha}
              comparadores={comparadores}
              entidadFed={entidadFed}
              setEntidadFed={setEntidadFed}
            />
            <div style={{ textAlign: "left" }}>
              <small style={{ fontSize: "11px" }}>
                {createLabel("informacionPieMapa")}
              </small>
            </div>
          </div>
          <div className="filtersWrapper">
            <AppBar position="static">
              <Toolbar sx={{ backgroundColor: "#EEE" }}>
                <Stack
                  spacing={2}
                  direction="row"
                  display="flex"
                  alignItems="center"
                >
                  <BtnBuscar
                    texto={showLoader ? "Buscando" : "Buscar"}
                    showLoader={showLoader}
                    onClick={() => {
                      buscar(filtrosAct);
                    }}
                  />
                  <Button
                    startIcon={<FilterAltOffIcon />}
                    color="primary"
                    onClick={() => {setCleanFilters(true); setSearchResults([]); setComparadores([])}}
                  >
                    Limpiar filtros
                  </Button>
                </Stack>
              </Toolbar>
            </AppBar>
            <Card
              sx={{
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  position: "static",
                },
              }}
            >
              <MenuFiltros
                setSearchResults={setSearchResults}
                setShowLoader={setShowLoader}
                cleanFilters={cleanFilters}
                setCleanFilters={setCleanFilters}
                setShowToast={setShowToast}
                setToastOptions={setToastOptions}
                showLoader={showLoader}
                setFiltrosAct={setFiltrosAct}
              />
            </Card>
          </div>
          <div className="menuFiltrosFloat">
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="info-2" startIcon={<FilterAltIcon />} onClick={(event) => {
                setAnchorEl(event.currentTarget)
              }}>
                Filtros
              </Button>
            </ThemeProvider>
            <Menu open={openFilterMenu} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
            <AppBar position="static">
              <Toolbar sx={{ backgroundColor: "#EEE" }}>
                <Stack
                  spacing={5}
                  direction="row"
                  display="flex"
                  alignItems="center"
                  width="100%"
                >
                  <BtnBuscar
                    texto={showLoader ? "Buscando" : "Buscar"}
                    showLoader={showLoader}
                    onClick={() => {
                      buscar(filtrosAct);
                      setAnchorEl(null);
                    }}
                  />
                  <Button
                    startIcon={<FilterAltOffIcon />}
                    color="primary"
                    onClick={() => setCleanFilters(true)}
                  >
                    Limpiar filtros
                  </Button>
                  <IconButton onClick={() => setAnchorEl(null)}>
                    <Close />
                  </IconButton>
                </Stack>
              </Toolbar>
            </AppBar>
            <Card
              sx={{
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  position: "static",
                },
              }}
            >
              <MenuFiltros
                setSearchResults={setSearchResults}
                setShowLoader={setShowLoader}
                cleanFilters={cleanFilters}
                setCleanFilters={setCleanFilters}
                setShowToast={setShowToast}
                setToastOptions={setToastOptions}
                showLoader={showLoader}
                setFiltrosAct={setFiltrosAct}
              />
            </Card>
            </Menu>
          </div>
        </div>
        <div className="tabContainer">
          <TabuladorMapa
            ficha={ficha}
            setFicha={setFicha}
            entidadFed={entidadFed}
            setEntidadFed={setEntidadFed}
            herramienta={herramienta}
            setHerramienta={setHerramienta}
            setFijar={setFijar}
            setComparadores={setComparadores}
            searchResults={searchResults}
            showLoader={showLoader}
            toastOptions={toastOptions}
            showToast={showToast}
            setShowToast={setShowToast}
          />
        </div>
      </div>
    </>
  );
}
